<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (AUTO LOAN)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12
                v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430)">
          <v-form ref="form2" class="multi-col-validation">
            <v-select v-model="status"
                      class="mx-2"
                      dense
                      outlined
                      label="Status"
                      :items="['Unpaid','Paid']"
                      :rules="rules.combobox_rule"
                      @change="selected_status"
            ></v-select>
            <v-checkbox v-model="is_search" label="Search?"
                        @change="selected_status(status)"></v-checkbox>
            <v-autocomplete
              v-if="is_search"
              dense
              outlined
              v-model="search"
              :items="search_items"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              class="mx-2"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
              @change="get_search_items_info"
            ></v-autocomplete>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_amount" label="Total Amount"
                          readonly></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_balance" label="Total Balance"
                          readonly></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430)?tabs:tabs1"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
              >
                <template v-slot:top>
                  Print data
                  <v-icon
                    class="mr-2"
                    color="success"
                    @click="print_report"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  LEGENDS -
                  (INFO-
                  <v-icon
                    class="mr-2"
                    color="success"
                  >
                    {{ icons.mdiFileAccountOutline }}
                  </v-icon>
                  )
                  (SOA-
                  <v-icon
                    class="mr-2"
                    color="success"
                  >
                    {{ icons.mdiPrinter }}
                  </v-icon>
                  )
                  <div
                    v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430)">
                    (TRANSFER-
                    <v-icon
                      class="mr-2"
                      color="warning"
                    >
                      {{ icons.mdiTransferRight }}
                    </v-icon>
                    ),
                    (RECALCULATE-
                    <v-icon
                      class="mr-2"
                      color="info"
                    >
                      {{ icons.mdiCalculator }}
                    </v-icon>
                    ),
                    (BOD CHARGES-
                    <v-icon
                      class="mr-2"
                      color="warning"
                    >
                      {{ icons.mdiFolderSwap }}
                    </v-icon>
                    ),
                    (TERMINATE-
                    <v-icon
                      class="mr-2"
                      color="error"
                    >
                      {{ icons.mdiMinusBox }}
                    </v-icon>
                    )
                  </div>
                </template>
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.brand }}
                    </td>
                    <td>
                      {{ item.unit }}
                    </td>
                    <td>
                      {{ item.plate_no }}
                    </td>
                    <td>
                      {{ formatPrice(item.cash_price) }}
                    </td>
                    <td>
                      {{ formatPrice(item.interest) }}
                    </td>
                    <td>
                      {{ formatPrice(item.total_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.borrower_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.balance) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{ icons.mdiPrinter }}
                      </v-icon>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_contract(item)"
                      >
                        {{ icons.mdiFileAccountOutline }}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="warning"
                        @click="transfer_data(item)"
                      >
                        {{ icons.mdiTransferRight }}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="info"
                        @click="recalculate_data(item)"
                      >
                        {{ icons.mdiCalculator }}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'&& (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{ icons.mdiFolderSwap }}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN'"
                        class="mr-2"
                        color="error"
                        @click="terminate(item)"
                      >
                        {{ icons.mdiMinusBox }}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items2"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.brand }}
                    </td>
                    <td>
                      {{ item.unit }}
                    </td>
                    <td>
                      {{ item.plate_no }}
                    </td>
                    <td>
                      {{ formatPrice(item.cash_price) }}
                    </td>
                    <td>
                      {{ formatPrice(item.interest) }}
                    </td>
                    <td>
                      {{ formatPrice(item.total_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.borrower_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.balance) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{ icons.mdiPrinter }}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items3"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.name }}
                    </td>
                    <td>
                      {{ item.brand }}
                    </td>
                    <td>
                      {{ item.unit }}
                    </td>
                    <td>
                      {{ item.plate_no }}
                    </td>
                    <td>
                      {{ formatPrice(item.cash_price) }}
                    </td>
                    <td>
                      {{ formatPrice(item.interest) }}
                    </td>
                    <td>
                      {{ formatPrice(item.total_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.borrower_loan) }}
                    </td>
                    <td>
                      {{ formatPrice(item.balance) }}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{ icons.mdiPrinter }}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
    <v-dialog v-model="Recalculate" persistent max-width="50%">
      <v-card>
        <v-card-text>
          <v-form ref="form2" class="multi-col-validation">
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">RECALCULATE</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2">
              <v-flex xs12 md12>
                <v-text-field
                  class="mx-2"
                  v-model="loan_amount"
                  label="Loan Amount"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                  min="0"
                  @change="calculation"
                  @keyup="calculation"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  class="mx-2"
                  v-model="terms"
                  label="Terms (months)"
                  dense
                  outlined
                  :rules="rules.default_max_45_character_and_no_empty_rule"
                  type="number"
                  min="0"
                  @change="calculation"
                  @keyup="calculation"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  class="mx-2"
                  v-model="monthly_due"
                  label="Monthly Ammortization"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
              <v-checkbox v-model="is_disable" label="Disable in 2 years payment" hide-details
                          class="me-3 mt-1" @change="calculation"></v-checkbox>
              <v-flex xs12 md12 v-if="!is_disable">
                <v-select
                  v-model="mode"
                  class="mx-2"
                  dense
                  outlined
                  label="Mode"
                  :items="['50/50 agreement','70/30 agreement']"
                  @change="calculation"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="!is_disable">
                <v-text-field
                  v-if="mode==='50/50 agreement'"
                  class="mx-2"
                  v-model="borrower_share"
                  label="Borrower Share (50%)"
                  dense
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="borrower_share"
                  label="Borrower Share (30%)"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12 v-if="!is_disable">
                <v-text-field
                  v-if="mode==='50/50 agreement'"
                  class="mx-2"
                  v-model="company_share"
                  label="Company Share (50%)"
                  dense
                  outlined
                  readonly
                ></v-text-field>
                <v-text-field
                  v-else
                  class="mx-2"
                  v-model="company_share"
                  label="Company Share (70%)"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md12>
                <v-text-field
                  class="mx-2"
                  v-model="total_borrower_loan"
                  label="Total Borrower Loan"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message_customize }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="recalculate"> Save Changes</v-btn>
      <v-btn color="error" @click="Recalculate = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="Transfer" persistent max-width="50%" :key="key">
      <v-card>
        <v-card-text>
          <v-form ref="form3" class="multi-col-validation">
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">TRANSFER LOAN</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2">
              <v-flex xs12 md12>
                <v-select
                  v-model="selection"
                  class="mx-2"
                  dense
                  outlined
                  label="Selection"
                  :items="['Employee','Non-Employee']"
                  @change="selected"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="selection==='Employee'">
                <v-select
                  v-model="category_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Category"
                  :items="category_items"
                  item-text="category"
                  item-value="id"
                  @change="selected_category"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="selection==='Employee'">
                <v-autocomplete
                  dense
                  outlined
                  v-model="searchh"
                  :items="search_itemss"
                  :loading="isLoading"
                  @keyup.enter="searchingg($event.target.value)"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Search"
                  placeholder="Press Enter to Search "
                  :prepend-icon="icons.mdiAccountSearchOutline"
                  @change="get_search_items_infoo"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">BORROWER'S INFORMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2" v-if="selection==='Employee'">
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="namee"
                  label="Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="branch"
                  label="Branch"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="align-center mx-2" v-else>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="namee"
                  label="Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="branch"
                  label="Branch"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message_customize }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="transfer"> Save Changes</v-btn>
      <v-btn color="error" @click="Transfer = false"> Close</v-btn>
    </v-dialog>
  </div>
</template>

<script>
import {
  mdiAccountOutline,
  mdiAccountCashOutline,
  mdiInformationOutline,
  mdiGoogleCirclesCommunities,
  mdiPrinter,
  mdiTransferRight,
  mdiFileAccountOutline,
  mdiCalculator,
  mdiMinusBox,
  mdiFolderSwap,
} from "@mdi/js";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
import category from "@/store/module/data/category";

const initialState = () => {
  return {
    selection: '',
    Recalculate: false,
    Transfer: false,
    headers: [
      {text: 'Name', value: 'date_of_deposit', sortable: false},
      {text: 'Brand', value: 'tin', sortable: false},
      {text: 'Unit', value: 'tin', sortable: false},
      {text: 'Plate #', value: 'tin', sortable: false},
      {text: 'Cash Price', value: 'tin', sortable: false},
      {text: 'Interest', value: 'tin', sortable: false},
      {text: 'Total Loan', value: 'tin', sortable: false},
      {text: 'Total Borrower Loan', value: 'tin', sortable: false},
      {text: 'Balance', value: 'tin', sortable: false},
      {text: 'Action', value: 'tin', sortable: false},
    ],
    data_items: [],
    data_items2: [],
    data_items3: [],
    search_items: [],
    search_itemss: [],
    category_items: [],
    tab: '',
    tabs: [
      {title: 'ACTIVE', icon: mdiGoogleCirclesCommunities},
      {title: 'TRANSFERRED', icon: mdiAccountOutline},
      {title: 'TERMINATED', icon: mdiAccountOutline},
    ],
    tabs1: [
      {title: 'ACTIVE', icon: mdiGoogleCirclesCommunities},
    ],
    is_search: false,
    status: '',
    search: '',
    searchh: '',
    alert_message_customize: '',
    mode: '',
    namee: '',
    alert_customize: false,
    isLoading: false,
    is_disable: false,
    total_amount: '0',
    total_balance: '0',
    total_borrower_loan: '0',
    company_share: '0',
    borrower_share: '0',
    monthly_due: '0',
    terms: '0',
    loan_amount: '0',
    id: 0,
    employee_id: 0,
    category_id: 0,
    branch: '',
    key: 0,
  }
}
export default {
  components: {
    snackBarDialog,
  },
  setup() {
    return {
      icons: {
        mdiAccountOutline,
        mdiAccountCashOutline,
        mdiInformationOutline,
        mdiPrinter,
        mdiTransferRight,
        mdiFileAccountOutline,
        mdiCalculator,
        mdiMinusBox,
        mdiFolderSwap,
      },
    }
  },
  data() {
    return initialState()
  },
  mounted() {
    if (!(this.position === 'ADMIN' || this.position === 'TREASURY' || this.position === 'ACCOUNTING' || this.position === 'CMD' || position === 'AR/AP' || (position === 'FINANCE' && employee_id === 430))) {
      this.status = 'Unpaid'
      this.searching(this.name)
    }
  },
  computed: {
    ...mapGetters('form_rules', ['rules']),
    ...mapGetters('authentication', ['address', 'contact', 'name', 'department', 'position']),
    ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
  },
  methods: {
    ...mapActions('system_data', ['change_snackbar']),
    ...mapActions('auto_loan', ['list_of_auto_loan', 'search_auto_loan', 'change_to_bod_charge', 'change_to_terminate', 'recalculate_auto_loan', 'transfer_auto_loan']),
    ...mapActions('request_particulars', ['initialize_new_particulars',]),
    ...mapActions('employee', ['search_active_employee',]),
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(',', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
    selected() {
      this.employee_id = 0
    },
    selected_category() {
      this.namee = ''
      this.branch = ''
      this.search_items = []
      this.search = ''
      this.employee_id = 0
    },
    searchingg(value) {
      this.namee = ''
      this.branch = ''
      this.employee_id = 0

      this.isLoading = true
      this.search_active_employee({
        search_word: value,
        category_id: this.category_id,
      })
        .then(response => {
          this.search_itemss = response.data
          this.isLoading = false
        })
    },
    get_search_items_infoo(value) {
      if (this.search_itemss.length > 0) {
        var index = this.search_itemss.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.employee_id = this.search_itemss[index].id
          this.namee = this.search_itemss[index].name
          this.branch = this.search_itemss[index].details.branch.branch_code
        } else {
          this.namee = ''
          this.branch = ''
          this.employee_id = 0
        }
      } else {
        this.namee = ''
        this.branch = ''
        this.employee_id = 0
      }
    },
    transfer_data(item) {
      if (confirm("PROCEED?")) {
        this.id = item.id
        this.Transfer = true
        this.key++
        this.initialize_new_particulars()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    recalculate_data(item) {
      if (confirm("PROCEED?")) {
        this.id = item.id
        this.Recalculate = true
        this.loan_amount = item.cash_price + ''
        this.terms = item.terms + ''
        this.is_disable = true
        this.calculation()
      }
    },
    terminate(item) {
      if (confirm("PROCEED?")) {
        this.change_to_terminate({
          id: item.id
        })
          .then(() => {
            this.selected_status(this.status)
          })
      }
    },
    bod_charge(item) {
      if (confirm("PROCEED?")) {
        this.change_to_bod_charge({
          id: item.id
        })
          .then(() => {
            this.selected_status(this.status)
          })
      }
    },
    searching(value) {
      this.data_items = []
      this.data_items2 = []
      this.data_items3 = []
      this.total_amount = '0'
      this.total_balance = '0'
      this.search_auto_loan({
        search_word: value,
        status: this.status
      })
        .then(response => {
          this.search_items = response.data
          this.isLoading = false
        })
    },
    get_search_items_info(value) {
      if (this.search_items.length > 0) {
        var index = this.search_items.map(function (x) {
          return x.id;
        }).indexOf(value)
        if (index != -1) {
          this.data_items = this.search_items[index].data[0].active
          this.data_items2 = this.search_items[index].data[0].transferred
          this.data_items3 = this.search_items[index].data[0].terminated
          this.total_amount = this.formatPrice(this.search_items[index].data[0].amount)
          this.total_balance = this.formatPrice(this.search_items[index].data[0].balance)
        } else {
          this.data_items = []
        }
      } else {
        this.data_items = []
      }
    },
    selected_status(value) {
      this.data_items = []
      this.data_items2 = []
      this.data_items3 = []
      this.total_amount = '0'
      this.total_balance = '0'
      if (!this.is_search) {
        this.list_of_auto_loan({
          status: value
        })
          .then(response => {
            this.data_items = response.data[0].active
            this.data_items2 = response.data[0].transferred
            this.data_items3 = response.data[0].terminated
            this.total_amount = this.formatPrice(response.data[0].amount)
            this.total_balance = this.formatPrice(response.data[0].balance)
          })
      }
    },
    calculation() {
      this.total_borrower_loan = parseFloat(this.loan_amount)
      this.monthly_due = parseFloat(this.loan_amount) / parseFloat(this.terms)
      if (!this.is_disable) {
        this.borrower_share = 0
        this.company_share = 0
        var discount = 0

        if (this.mode === '50/50 agreement') {
          discount = (parseFloat(this.monthly_due) * 0.50) * (parseFloat(this.terms) >= 24 ? 24 : parseFloat(this.terms))
          this.borrower_share = parseFloat(this.loan_amount) - discount
          this.company_share = parseFloat(this.loan_amount) - discount
        } else if (this.mode === '70/30 agreement') {
          discount = (parseFloat(this.monthly_due) * 0.70) * (parseFloat(this.terms) >= 24 ? 24 : parseFloat(this.terms))
          this.borrower_share = parseFloat(this.loan_amount) - discount
          this.company_share = parseFloat(this.loan_amount) - this.borrower_share
        }
        this.total_borrower_loan = this.total_borrower_loan - discount
      }
    },
    transfer() {
      this.alert_customize = false
      if (this.$refs.form3.validate()) {
        const data = new FormData()
        data.append('id', this.id);
        data.append('name', this.namee);
        data.append('employee_id', this.employee_id);
        this.transfer_auto_loan(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.Transfer = false
            this.selected_status(this.status)
          })
          .catch(error => {
            this.alert_customize = true
            this.alert_customize = error
          })
      } else {
        this.alert_customize = true
        this.alert_customize = 'Please Fill up the field/s'
      }
    },
    recalculate() {
      this.alert_customize = false
      if (this.$refs.form2.validate()) {
        const data = new FormData()
        data.append('id', this.id);
        data.append('cash_price', this.loan_amount);
        data.append('total_interest', 0);
        data.append('total_loan', this.total_borrower_loan);
        data.append('loan_terms', this.terms);
        data.append('monthly_ammortization', this.monthly_due);
        data.append('borrower_seventy', this.mode === '70/30 agreement' ? this.borrower_share : 0);
        data.append('company_thirty', this.mode === '70/30 agreement' ? this.company_share : 0);
        data.append('borrower_fifty', this.mode === '50/50 agreement' ? this.borrower_share : 0);
        data.append('company_fifty', this.mode === '50/50 agreement' ? this.company_share : 0);
        data.append('total_borrower_loan', this.total_borrower_loan);
        this.recalculate_auto_loan(data)
          .then(response => {
            var color = 'success'
            if (response.status === 201) {
              color = 'error'
            }
            this.change_snackbar({
              show: true,
              color: color,
              text: response.data,
            })
            this.Recalculate = false
            this.selected_status(this.status)
          })
          .catch(error => {
            this.alert_customize = true
            this.alert_customize = error
          })
      } else {
        this.alert_customize = true
        this.alert_customize = 'Please Fill up the field/s'
      }
    },
    print_report() {
      var imgData = this.company_logo
      var payments_array = []
      var widths = [120, 100, 100, 60, 60, 60, 60, 100, 60]
      var payment = 0
      payments_array.push([
        {text: 'Name', alignment: 'left', style: 'label',},
        {text: 'Branch', alignment: 'left', style: 'label'},
        {text: 'Unit', alignment: 'left', style: 'label'},
        {text: 'Plate #', alignment: 'left', style: 'label'},
        {text: 'Cash Price', alignment: 'left', style: 'label'},
        {text: 'Interest', alignment: 'left', style: 'label'},
        {text: 'Total Loan', alignment: 'left', style: 'label'},
        {text: 'Total Borrower Loan', alignment: 'left', style: 'label'},
        {text: 'Balance', alignment: 'left', style: 'label'},
      ])
      if (this.data_items.length > 0) {
        this.data_items.forEach(function (item) {
          payments_array.push([
            {text: item.name, alignment: 'left', style: 'tableExample'},
            {text: item.brand, alignment: 'left', style: 'tableExample'},
            {text: item.unit, alignment: 'left', style: 'tableExample'},
            {text: item.plate_no, alignment: 'left', style: 'tableExample'},
            {
              text: (item.cash_price / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left', style: 'tableExample'
            },
            {
              text: (item.interest / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left', style: 'tableExample'
            },
            {
              text: (item.total_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left', style: 'tableExample'
            },
            {
              text: (item.borrower_loan / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left', style: 'tableExample'
            },
            {
              text: (item.balance / 1)
                .toFixed(2)
                .replace(',', '.')
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
              alignment: 'left', style: 'tableExample'
            },
          ])
        })
      }
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'landscape',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE GROUP OF COMPANIES',
                  {text: this.address, style: 'subheader'},
                  {
                    text: this.contact,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'AUTO LOAN REPORT',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.department,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {image: this.damayan, width: 54, height: 40, style: 'logo'},
              {image: this.chapel, width: 54, height: 40, style: 'logo'},
              {image: this.factory, width: 54, height: 40, style: 'logo'},
              {image: this.coop, width: 54, height: 40, style: 'logo'},
              {image: this.printing, width: 54, height: 40, style: 'logo'},
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.saving = false
    },
    print_soa(value) {
      var imgData = this.company_logo
      var payments_array = []
      var widths = [100, 60, 80, 100, 60]
      var payment = 0
      payments_array.push([
        {text: 'Month Of', alignment: 'left', style: 'label',},
        {text: 'Duration', alignment: 'left', style: 'label'},
        {text: 'Date', alignment: 'left', style: 'label'},
        {text: 'Particulars', alignment: 'left', style: 'label'},
        {text: 'Amount', alignment: 'left', style: 'label'},
      ])
      if (value.payment.length > 0) {
        value.payment.forEach(function (item) {
          if (item.deposit_slip != null) {
            payment += parseFloat(item.amount)
            payments_array.push([
              {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
              {text: item.duration, alignment: 'left', style: 'tableExample'},
              {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
              {text: item.particulars, alignment: 'left', style: 'tableExample'},
              {
                text: (item.amount / 1)
                  .toFixed(2)
                  .replace(',', '.')
                  .toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                alignment: 'left', style: 'tableExample'
              },
            ])
          }
        })
      }
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE GROUP OF COMPANIES',
                  {text: this.address, style: 'subheader'},
                  {
                    text: this.contact,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'AUTO LOAN - STATEMENT OF ACCOUNT (SOA)',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: [
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'NAME: ',
                      {
                        text: value.name,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Car Value: ',
                      {
                        text: this.formatPrice(value.cash_price),
                        style: 'main_data', color: 'green',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Date of Loan: ',
                      {
                        text: value.date,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Interest: ',
                      {
                        text: this.formatPrice(value.cash_price),
                        style: 'main_data', color: 'red',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      ' ',
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Total: ',
                      {
                        text: this.formatPrice(value.total_loan),
                        style: 'main_data', color: 'blue',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Brand: ',
                      {
                        text: value.brand,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      '----------------------------------------',
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Unit: ',
                      {
                        text: value.unit,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Share"s ',
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Plate #: ',
                      {
                        text: value.plate_no,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Company: ',
                      {
                        text: this.formatPrice(value.company_share),
                        style: 'main_data', color: 'orange',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Classification: ',
                      {
                        text: value.vehicle_classification,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Borrower: ',
                      {
                        text: this.formatPrice(value.borrower_loan),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      ' ',
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Total Payment: ',
                      {
                        text: this.formatPrice(payment),
                        style: 'main_data', color: 'blue',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      ' ',
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Balance: ',
                      {
                        text: this.formatPrice(value.balance),
                        style: 'main_data', color: 'red',
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          'PAYMENT',
          {
            table: {
              widths: widths,
              body: payments_array,
              style: {fontSize: 1},
            },
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.department,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {image: this.damayan, width: 54, height: 40, style: 'logo'},
              {image: this.chapel, width: 54, height: 40, style: 'logo'},
              {image: this.factory, width: 54, height: 40, style: 'logo'},
              {image: this.coop, width: 54, height: 40, style: 'logo'},
              {image: this.printing, width: 54, height: 40, style: 'logo'},
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.saving = false
    },
    print_contract(value) {
      var imgData = this.company_logo
      //call pdfmake
      var pdfMake = require('pdfmake/build/pdfmake.js')
      if (pdfMake.vfs == undefined) {
        var pdfFonts = require('pdfmake/build/vfs_fonts.js')
        pdfMake.vfs = pdfFonts.pdfMake.vfs
      }
      var docDefinition = {
        pageSize: {
          width: 612,
          height: 936,
        },
        pageOrientation: 'portrait',
        content: [
          {
            columns: [
              {image: imgData, width: 54, height: 54, style: 'logo'},
              {
                stack: [
                  'GOODLIFE GROUP OF COMPANIES',
                  {text: this.address, style: 'subheader'},
                  {
                    text: this.contact,
                    style: 'subheader',
                  },
                  {
                    text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                    style: 'printed_label',
                  },
                ],
                style: 'header',
              },
            ],
          },
          '_______________________________________________________________________________________________',
          {
            stack: [
              {
                columns: [
                  {
                    text: 'AUTO LOAN',
                    style: {
                      fontSize: 16,
                      alignment: 'center',
                      bold: true,
                      color: 'blue',
                    },
                  },
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: [
              {
                style: 'main_info',
                text: [
                  'LOAN ID: ',
                  {
                    text: value.id,
                    style: 'main_data',
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'NAME: ',
                      {
                        text: value.name,
                        style: 'main_data',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Date of Loan: ',
                      {
                        text: value.date,
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Place of Birth: ',
                  {
                    text: value.place_of_birth,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Present Address: ',
                  {
                    text: value.present_address,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Name of Employer: ',
                  {
                    text: value.name_of_employer,
                    style: 'main_data',
                  },
                ],
              },
              {
                style: 'main_info',
                text: [
                  'Employer Address: ',
                  {
                    text: value.employer_address,
                    style: 'main_data',
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Employment Date: ',
                      {
                        text: value.employment_date,
                        style: 'main_data',
                      },
                    ],
                  },
                  {
                    style: 'main_info',
                    text: [
                      'Position: ',
                      {
                        text: value.position_designation,
                        style: 'main_data',
                      },
                    ],
                  },

                ],
              },
              {
                columns: [
                  {text: ''},
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Car Value: ',
                      {
                        text: this.formatPrice(value.cash_price),
                        style: 'main_data', color: 'green',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {text: ''},
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Interest: ',
                      {
                        text: this.formatPrice(value.interest),
                        style: 'main_data', color: 'red',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Year Model: ',
                      {
                        text: value.year_model,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      'Total: ',
                      {
                        text: this.formatPrice(value.total_loan),
                        style: 'main_data', color: 'blue',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Brand: ',
                      {
                        text: value.brand,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_info',
                    text: [
                      '----------------------------------------',
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Unit: ',
                      {
                        text: value.unit,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Share"s ',
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Plate #: ',
                      {
                        text: value.plate_no,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Company: ',
                      {
                        text: this.formatPrice(value.company_share),
                        style: 'main_data', color: 'orange',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      'Classification: ',
                      {
                        text: value.vehicle_classification,
                        style: 'main_data',
                      },
                    ],
                  },
                  {text: ''},
                  {
                    style: 'main_data',
                    text: [
                      'Borrower: ',
                      {
                        text: this.formatPrice(value.borrower_loan),
                        style: 'main_data',
                      },
                    ],
                  },
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      ' ',
                    ],
                  },
                  {text: ''},
                ],
              },
              {
                columns: [
                  {
                    style: 'main_info',
                    text: [
                      ' ',
                    ],
                  },
                  {text: ''},
                  {text: ''},
                ],
              },
            ],
          },
          {
            columns: [{text: ' '}],
          },
          {
            stack: [
              {
                columns: [{text: ' '}],
              },
              {
                columns: [{text: ' '}],
              },
              {
                columns: [
                  {
                    text: 'PROCESSED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: 'DEPARTMENT RECEIVER',
                    style: 'main_data_2',
                  },
                  {
                    text: 'RECEIVED BY',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.name,
                    style: 'main_data_2',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                  {
                    text: '_____________________',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: this.department,
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                  {
                    text: 'Signature Over Printed Name',
                    style: 'main_data_2',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'VERIFIED BY',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: '_________________________________________',
                  },
                  {
                    text: ' ',
                  },
                  {
                    text: ' ',
                  },
                ],
              },
              {
                columns: [
                  {
                    text: 'BOARD OF DIRECTOR',
                    style: 'main_data_2',
                  },
                  {
                    text: '',
                  },
                  {
                    text: '',
                  },
                ],
              },
            ],
          },
          {
            columns: [
              {image: this.damayan, width: 54, height: 40, style: 'logo'},
              {image: this.chapel, width: 54, height: 40, style: 'logo'},
              {image: this.factory, width: 54, height: 40, style: 'logo'},
              {image: this.coop, width: 54, height: 40, style: 'logo'},
              {image: this.printing, width: 54, height: 40, style: 'logo'},
            ],
          },
        ],
        footer: {
          // columns: [
          //     {
          //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
          //         alignment: 'center',
          //         style: 'tableExample'
          //     }
          // ]
        },
        styles: {
          tableExample: {
            fontSize: 9,
          },
          tableExample2: {
            fontSize: 7,
          },
          header: {
            fontSize: 21,
            bold: true,
            alignment: 'left',
            margin: [6, 6, 0, 5], //[left, top, right, bottom]
          },
          subheader: {
            fontSize: 11,
          },
          logo: {
            alignment: 'center',
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          printed_label: {
            alignment: 'right',
            fontSize: 9,
            margin: [0, 0, 0, 0], //[left, top, right, bottom]
          },
          main_data: {
            margin: [0, 2, 0, 2],
            fontSize: 11,
            bold: true,
            alignment: 'left',
          },
          main_data_2: {
            margin: [0, 2, 0, 2],
            fontSize: 8,
            bold: true,
            alignment: 'left',
          },
          main_info: {
            margin: [0, 2, 0, 2],
            fontSize: 10,
            alignment: 'left',
          },
        },
      }
      pdfMake.createPdf(docDefinition).open()
      this.saving = false
    },
  }
}
</script>
