<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">COMPANY RECEIVABLE (MOTOR FINANCE)</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-layout row wrap class="align-center mx-2 mt-5">
        <v-flex xs12 md12
                v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD'|| position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430)">
          <v-form ref="form2" class="multi-col-validation">
            <v-select v-model="status"
                      class="mx-2"
                      dense
                      outlined
                      label="Status"
                      :items="['Unpaid','Paid']"
                      :rules="rules.combobox_rule"
                      @change="selected_status"
            ></v-select>
            <v-checkbox v-model="is_search" label="Search?"
                        @change="selected_status(status)"></v-checkbox>
            <v-autocomplete
              v-if="is_search"
              dense
              outlined
              v-model="search"
              :loading="isLoading"
              @keyup.enter="searching($event.target.value)"
              hide-no-data
              hide-selected
              item-text="name"
              item-value="id"
              label="Search"
              class="mx-2"
              placeholder="Press Enter to Search "
              :prepend-icon="icons.mdiAccountSearchOutline"
            ></v-autocomplete>
          </v-form>
        </v-flex>
        <v-layout col>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_amount" label="Total Amount"
                          readonly></v-text-field>
          </v-flex>
          <v-flex xs12 md6 class="mx-2">
            <v-text-field outlined v-model="total_balance" label="Total Balance"
                          readonly></v-text-field>
          </v-flex>
        </v-layout>
        <v-flex xs12 md12 class="mx-2">
          LEGENDS -
          (SOA-
          <v-icon
            class="mr-2"
            color="success"
          >
            {{icons.mdiPrinter}}
          </v-icon>
          )
          <div
            v-if="position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD' || position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430)">
            (TRANSFER-
            <v-icon
              class="mr-2"
              color="info"
            >
              {{icons.mdiTransferRight}}
            </v-icon>
            ),
            (BOD CHARGES-
            <v-icon
              class="mr-2"
              color="warning"
            >
              {{icons.mdiFolderSwap}}
            </v-icon>
            ),(RECALCULATE-
            <v-icon
              class="mr-2"
              color="error"
            >
              {{icons.mdiCalculator}}
            </v-icon>
            )
          </div>
        </v-flex>
        <v-flex xs12 md12 class="mx-2">
          <v-tabs
            v-model="tab"
            show-arrows
          >
            <v-tab
              v-for="tab in position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' || position === 'CMD' || position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430)?tabs:tabs1"
              :key="tab.title"
            >
              <v-icon
                size="20"
                class="me-3"
              >
                {{ tab.icon }}
              </v-icon>
              <span>{{ tab.title }}</span>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab">
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN' && (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="info"
                        @click="transfer_data(item)"
                      >
                        {{icons.mdiTransferRight}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN' && (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN' && (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="error"
                        @click="to_recalculate(item)"
                      >
                        {{icons.mdiCalculator}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
            <v-tab-item>
              <v-data-table dense
                            :headers="headers"
                            :items="data_items2"
              >
                <template v-slot:item="{ item }">
                  <tr>
                    <td>
                      {{ item.id }}
                    </td>
                    <td>
                      {{ item.employee!=null?item.employee.last_name+', '+item.employee.first_name
                      +' '+item.employee.middle_name:item.name }}
                    </td>
                    <td>
                      {{ item.purpose }}
                    </td>
                    <td>
                      {{formatPrice(item.amount)}}
                    </td>
                    <td>
                      {{ balance_calculation(item)}}
                    </td>
                    <td>
                      <v-icon
                        class="mr-2"
                        color="success"
                        @click="print_soa(item)"
                      >
                        {{icons.mdiPrinter}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN' && (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="warning"
                        @click="bod_charge(item)"
                      >
                        {{icons.mdiFolderSwap}}
                      </v-icon>
                      <v-icon
                        v-if="position === 'ADMIN' && (employee_id===1||employee_id===2)"
                        class="mr-2"
                        color="error"
                        @click="to_recalculate(item)"
                      >
                        {{icons.mdiCalculator}}
                      </v-icon>
                    </td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-flex>
      </v-layout>
    </v-card>
    <v-dialog v-model="is_can_edit" persistent max-width="80%">
      <v-card>
        <v-form ref="form" class="multi-col-validation">
          <v-toolbar dense dark color="primary">
            <v-toolbar-title><h4 class="font-weight-light">RECALCULATE MOTOR FINANCE</h4>
            </v-toolbar-title>
          </v-toolbar>

          <v-layout row wrap class="align-center mx-2 mt-9">
            <v-flex xs12 md6>
              <v-text-field
                class="mx-2"
                v-model="brand"
                label="Brand/Unit"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
              <v-text-field
                class="mx-2"
                v-model="dealer"
                label="Dealer"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="model"
                label="Model/Color"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="terms"
                label="Terms (months)"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
                @change="calculation"
                @keyup="calculation"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="amount"
                label="Amount"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                min="0"
                @change="calculation"
                @keyup="calculation"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="interest"
                label="Interest"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
                min="0"
                @change="calculation"
                @keyup="calculation"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="total_interest"
                label="Total Interest"
                dense
                disabled
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="total"
                label="Grand Total"
                dense
                readonly
                disabled
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="monthly_due"
                label="Monthly Due"
                dense
                disabled
                readonly
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="due_date"
                label="Due Date"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-flex>
            <v-flex xs12 md4>
              <v-text-field
                class="mx-2"
                v-model="downpayment"
                label="Down Payment"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-flex>
          </v-layout>

          <v-row>
            <!-- alert -->
            <v-col cols="12" v-show="alert">
              <v-alert
                color="warning"
                text
                class="mb-0"
              >
                <div class="d-flex align-start">
                  <v-icon color="warning">
                    {{ icons.mdiAlertOutline }}
                  </v-icon>

                  <div class="ms-3">
                    <p class="text-base font-weight-medium mb-1">
                      {{alert_message}}
                    </p>
                  </div>
                </div>
              </v-alert>
            </v-col>
            <v-col cols="12" md="12">
              <v-btn
                class="w-full"
                color="primary"
                @click="done_edit"
                v-if="!saving_data"
              >
                Done
              </v-btn>
              <v-progress-circular
                :size=50
                :width="5"
                color="primary"
                indeterminate
                v-else
              ></v-progress-circular>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
      <v-btn color="error" @click="is_can_edit = false"> Close</v-btn>
    </v-dialog>
    <v-dialog v-model="is_can_transfer" persistent max-width="50%" :key="key">
      <v-card>
        <v-card-text>
          <v-form ref="form3" class="multi-col-validation">
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">TRANSFER MOTOR FINANCEs</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2">
              <v-flex xs12 md12>
                <v-select
                  v-model="selection"
                  class="mx-2"
                  dense
                  outlined
                  label="Selection"
                  :items="['Employee','Non-Employee']"
                  @change="selected"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="selection==='Employee'">
                <v-select
                  v-model="category_id"
                  class="mx-2"
                  dense
                  outlined
                  label="Category"
                  :items="category_items"
                  item-text="category"
                  item-value="id"
                  @change="selected_category"
                ></v-select>
              </v-flex>
              <v-flex xs12 md12 v-if="selection==='Employee'">
                <v-autocomplete
                  dense
                  outlined
                  v-model="searchh"
                  :items="search_itemss"
                  :loading="isLoading"
                  @keyup.enter="searchingg($event.target.value)"
                  hide-no-data
                  hide-selected
                  item-text="name"
                  item-value="id"
                  label="Search"
                  placeholder="Press Enter to Search "
                  :prepend-icon="icons.mdiAccountSearchOutline"
                  @change="get_search_items_infoo"
                ></v-autocomplete>
              </v-flex>
            </v-layout>
            <v-toolbar dense dark color="primary" class="mt-5 mb-5">
              <v-toolbar-title><h4 class="font-weight-light">BORROWER'S INFORMATION</h4>
              </v-toolbar-title>
            </v-toolbar>
            <v-layout row wrap class="align-center mx-2" v-if="selection==='Employee'">
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="namee"
                  label="Name"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="branch"
                  label="Branch"
                  dense
                  outlined
                  readonly
                ></v-text-field>
              </v-flex>
            </v-layout>
            <v-layout row wrap class="align-center mx-2" v-else>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="namee"
                  label="Name"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
              <v-flex xs12 md6>
                <v-text-field
                  class="mx-2"
                  v-model="branch"
                  label="Branch"
                  dense
                  outlined
                  :rules="rules.default_max_255_character_and_no_empty_rule"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-form>
          <v-col cols="12" v-show="alert_customize">
            <v-alert
              color="warning"
              text
              class="mb-0"
            >
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{alert_message_customize}}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
        </v-card-text>
      </v-card>
      <v-btn color="primary" @click="transfer"> Save Changes</v-btn>
      <v-btn color="error" @click="is_can_transfer = false"> Close</v-btn>
    </v-dialog>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiGoogleCirclesCommunities,
    mdiPrinter, mdiTransferRight, mdiFolderSwap, mdiDelete, mdiCalculator,
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'
  import category from "@/store/module/data/category";

  const initialState = () => {
    return {
      saving_data: false,
      alert: false,
      alert_message: '',

      new_employee_id: '',
      key: 0,
      is_can_edit: false,
      is_can_transfer: false,
      downpayment: 0,
      due_date: '',
      monthly_due: 0,
      total: 0,
      total_interest: 0,
      interest: 0,
      amount: 0,
      terms: 0,
      model: '',
      dealer: '',
      brand: '',

      selection: '',
      category_id: '',

      headers: [
        {text: 'ID', value: 'date_of_deposit', sortable: false},
        {text: 'Name', value: 'employee.last_name', sortable: true},
        {text: 'Description', value: 'tin', sortable: false},
        {text: 'Amount', value: 'tin', sortable: false},
        {text: 'Balance', value: 'tin', sortable: false},
        {text: 'Action', value: 'tin', sortable: false},
      ],
      selected_data: {},
      data_items: [],
      data_items2: [],

      tab: '',
      tabs: [
        {title: 'EMPLOYEE', icon: mdiGoogleCirclesCommunities},
        {title: 'OTHERS', icon: mdiAccountOutline},
      ],
      tabs1: [
        {title: 'EMPLOYEE', icon: mdiGoogleCirclesCommunities},
      ],
      is_search: false,
      status: '',
      search: '',
      isLoading: false,
      total_amount: '0',
      total_balance: '0',
      id: 0,

      searchh: '',
      namee: '',
      branch: '',

      alert_message_customize: '',
      alert_customize: false,

      search_itemss: [],
      category_items: [],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiPrinter,
          mdiTransferRight,
          mdiFolderSwap,
          mdiDelete,
          mdiCalculator,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      if (!(this.position === 'ADMIN' || this.position === 'TREASURY' || this.position === 'ACCOUNTING' || this.position === 'CMD'|| position === 'AR/AP' || (position === 'FINANCE' && employee_id=== 430))) {
        this.status = 'Unpaid'
        this.searching(this.employee_id)
      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id', 'address', 'contact', 'name', 'department', 'position']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo', 'damayan', 'chapel', 'factory', 'coop', 'printing']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('motor_finance', ['list_of_motor_receivable', 'change_motor_to_bod_charge', 'search_list_of_motor', 'recalculation_motor', 'transfer_motor_to_other_emp']),
      ...mapActions('request_particulars', ['initialize_new_particulars',]),
      ...mapActions('employee', ['search_active_employee',]),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      balance_calculation(item) {
        var total = item.amount
        var payment = 0
        item.payment.forEach(function (item) {
          payment += parseFloat(item.amount)
        });
        var balance = (total - payment)

        return this.formatPrice(balance)
      },
      searching(value) {
        this.search = value
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.total_balance = '0'

        this.search_list_of_motor({
          search_word: value,
          status: this.status
        })
          .then(response => {
            this.total_amount = response.data[0].total_amount
            this.total_balance = response.data[0].total_balance
            this.data_items = response.data[0].employee
            this.data_items2 = response.data[0].others
            this.isLoading = false
          })
      },
      selected_status(value) {
        this.data_items = []
        this.data_items2 = []
        this.total_amount = '0'
        this.total_balance = '0'
        if (!this.is_search) {
          this.list_of_motor_receivable({
            status: value
          })
            .then(response => {
              this.total_amount = response.data[0].total_amount
              this.total_balance = response.data[0].total_balance
              this.data_items = response.data[0].employee
              this.data_items2 = response.data[0].others
              this.isLoading = false
            })
        } else {
          this.searching(this.search)
        }
      },
      selected_category() {
        this.namee = ''
        this.branch = ''
        this.search_items = []
        this.search = ''
        this.new_employee_id = 0
      },
      searchingg(value) {
        this.namee = ''
        this.branch = ''
        this.new_employee_id = 0

        this.isLoading = true
        this.search_active_employee({
          search_word: value,
          category_id: this.category_id,
        })
          .then(response => {
            this.search_itemss = response.data
            this.isLoading = false
          })
      },
      get_search_items_infoo(value) {
        if (this.search_itemss.length > 0) {
          var index = this.search_itemss.map(function (x) {
            return x.id;
          }).indexOf(value)
          if (index != -1) {
            this.new_employee_id = this.search_itemss[index].id
            this.namee = this.search_itemss[index].name
            this.branch = this.search_itemss[index].details.branch.branch_code
          } else {
            this.namee = ''
            this.branch = ''
            this.new_employee_id = 0
          }
        } else {
          this.namee = ''
          this.branch = ''
          this.new_employee_id = 0
        }
      },
      selected() {
        this.new_employee_id = 0
      },
      transfer_data(item) {
        if (confirm("PROCEED?")) {
          this.selected_data = item
          this.is_can_transfer = true
          this.key++
          this.initialize_new_particulars()
            .then(response => {
              this.category_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
      },
      transfer() {
        if (confirm("PROCEED TO TRANSFER?")) {
          this.transfer_motor_to_other_emp({
            id: this.selected_data.id,
            employee_id: this.new_employee_id,
          })
            .then(() => {
              this.is_can_edit = false
              this.is_can_transfer = false
              this.selected_status(this.status)
            })
        }
      },
      bod_charge(item) {
        if (confirm("PROCEED?")) {
          this.change_motor_to_bod_charge({
            id: item.id
          })
            .then(() => {
              this.selected_status(this.status)
            })
        }
      },
      to_recalculate(item) {
        if (confirm("PROCEED TO RECALCULATE?")) {
          this.is_can_edit = true
          var details = (item.purpose).split('# ')
          if (details.length === 11) {
            this.brand = details[0].split('Brand-')[1] + ''
            this.dealer = details[1].split('Dealer-')[1] + ''
            this.model = details[2].split('Model-')[1] + ''
            this.terms = parseFloat(details[3].split('Terms-')[1] + '') + ''
            this.interest = parseFloat((details[4].split('Int(%)-')[1]).split('%')[0] + '') + ''
            this.amount = parseFloat(details[5].split('Total-')[1] + '') + ''
            this.due_date = moment(details[9].split('DueDate-')[1], 'MM-DD-YYYY').format('YYYY-MM-DD')
            this.downpayment = parseFloat(details[10].split('DownPayment-')[1] + '') + ''
          }
          this.calculation()
          this.selected_data = item
        }
      },
      calculation() {
        var to_iner = parseFloat(this.terms) * parseFloat(this.interest)
        this.total_interest = (parseFloat(this.amount) * (parseFloat(to_iner) / 100))
        this.total = parseFloat(this.amount) + parseFloat(this.total_interest)
        this.monthly_due = parseFloat(this.total) / parseFloat(this.terms)
      },
      done_edit() {
        this.saving_data = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('id', this.selected_data.id);
          data.append('amount', this.total);
          data.append('purpose', 'Brand-' + this.brand + ' # Dealer-' + this.dealer + ' # Model-' + this.model + ' # Terms-' + this.terms + ' # Int(%)-' + this.interest + '% # Total-' + this.amount + ' # Interest-' + this.formatPrice(this.total_interest) + ' # GTotal-' + this.formatPrice(this.total) + ' # Monthly-' + this.formatPrice(this.monthly_due) + ' # DueDate-' + moment(this.due_date, 'YYYY-MM-DD').format('MM-DD-YYYY') + ' # DownPayment-' + this.formatPrice(this.downpayment));
          this.recalculation_motor(data)
            .then(response => {
              var color = 'error'
              if (response.status === 200) {
                color = 'success'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving_data = false
              this.is_can_edit = false
              this.selected_status()
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_data = false
        }
      },
      print_soa(value) {
        var imgData = this.company_logo
        var payments_array = []
        var widths = [100, 60, 80, 100, 60]
        var payment = 0
        payments_array.push([
          {text: 'Month Of', alignment: 'left', style: 'label',},
          {text: 'Duration', alignment: 'left', style: 'label'},
          {text: 'Date', alignment: 'left', style: 'label'},
          {text: 'Particulars', alignment: 'left', style: 'label'},
          {text: 'Amount', alignment: 'left', style: 'label'},
        ])
        if (value.payment.length > 0) {
          value.payment.forEach(function (item) {
            if (item.deposit_slip != null) {
              payment += parseFloat(item.amount)
              payments_array.push([
                {text: item.transaction_month.month_of, alignment: 'left', style: 'tableExample'},
                {text: item.duration, alignment: 'left', style: 'tableExample'},
                {text: item.deposit_slip.date, alignment: 'left', style: 'tableExample'},
                {text: item.particulars, alignment: 'left', style: 'tableExample'},
                {
                  text: (item.amount / 1)
                    .toFixed(2)
                    .replace(',', '.')
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ','),
                  alignment: 'left', style: 'tableExample'
                },
              ])
            }
          })
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs
        }
        var docDefinition = {
          pageSize: {
            width: 612,
            height: 936,
          },
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE GROUP OF COMPANIES',
                    {text: this.address, style: 'subheader'},
                    {
                      text: this.contact,
                      style: 'subheader',
                    },
                    {
                      text: 'Printed as of: ' + moment().format('MMMM D, YYYY'),
                      style: 'printed_label',
                    },
                  ],
                  style: 'header',
                },
              ],
            },
            '_______________________________________________________________________________________________',
            {
              stack: [
                {
                  columns: [
                    {
                      text: 'MOTOR FINANCE - STATEMENT OF ACCOUNT (SOA)',
                      style: {
                        fontSize: 16,
                        alignment: 'center',
                        bold: true,
                        color: 'blue',
                      },
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            {
              columns: [{text: ' '}],
            },
            {
              stack: [
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'ID#: ',
                        {
                          text: value.id,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Amount: ',
                        {
                          text: this.formatPrice(value.amount),
                          style: 'main_data', color: 'blue',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'NAME: ',
                        {
                          text: value.employee != null ? value.employee.last_name + ', ' + value.employee.first_name
                            + ' ' + value.employee.middle_name : value.name,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Total Payed: ',
                        {
                          text: this.formatPrice(payment),
                          style: 'main_data', color: 'green',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Date: ',
                        {
                          text: value.date,
                          style: 'main_data',
                        },
                      ],
                    },
                    {text: ''},
                    {
                      style: 'main_info',
                      text: [
                        'Balance: ',
                        {
                          text: this.formatPrice(parseFloat(value.amount) - parseFloat(payment)),
                          style: 'main_data', color: 'red',
                        },
                      ],
                    },
                  ],
                },
                {
                  columns: [
                    {
                      style: 'main_info',
                      text: [
                        'Purpose: ',
                        {
                          text: value.purpose,
                          style: 'main_data',
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              columns: [{text: ' '}],
            },
            'PAYMENT',
            {
              table: {
                widths: widths,
                body: payments_array,
                style: {fontSize: 1},
              },
            },
            {
              stack: [
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [{text: ' '}],
                },
                {
                  columns: [
                    {
                      text: 'PROCESSED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: 'DEPARTMENT RECEIVER',
                      style: 'main_data_2',
                    },
                    {
                      text: 'RECEIVED BY',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.name,
                      style: 'main_data_2',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                    {
                      text: '_____________________',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: this.department,
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                    {
                      text: 'Signature Over Printed Name',
                      style: 'main_data_2',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'VERIFIED BY',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: '_________________________________________',
                    },
                    {
                      text: ' ',
                    },
                    {
                      text: ' ',
                    },
                  ],
                },
                {
                  columns: [
                    {
                      text: 'BOARD OF DIRECTOR',
                      style: 'main_data_2',
                    },
                    {
                      text: '',
                    },
                    {
                      text: '',
                    },
                  ],
                },
              ],
            },
            {
              columns: [
                {image: this.damayan, width: 54, height: 40, style: 'logo'},
                {image: this.chapel, width: 54, height: 40, style: 'logo'},
                {image: this.factory, width: 54, height: 40, style: 'logo'},
                {image: this.coop, width: 54, height: 40, style: 'logo'},
                {image: this.printing, width: 54, height: 40, style: 'logo'},
              ],
            },
          ],
          footer: {
            // columns: [
            //     {
            //         text: 'Generated with Goodlife Savings & Credit Cooperative System',
            //         alignment: 'center',
            //         style: 'tableExample'
            //     }
            // ]
          },
          styles: {
            tableExample: {
              fontSize: 9,
            },
            tableExample2: {
              fontSize: 7,
            },
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 5], //[left, top, right, bottom]
            },
            subheader: {
              fontSize: 11,
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 0, 0, 0], //[left, top, right, bottom]
            },
            main_data: {
              margin: [0, 2, 0, 2],
              fontSize: 11,
              bold: true,
              alignment: 'left',
            },
            main_data_2: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
              bold: true,
              alignment: 'left',
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
              alignment: 'left',
            },
          },
        }
        pdfMake.createPdf(docDefinition).open()
        this.saving = false
      },
    }
  }
</script>
