<template>
  <div>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">BIR MONTHLY GOV. BENEFITS</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-row dense class="mt-3">
        <v-col
          md="2"
          cols="12"
        >
          <v-select
            v-model="year"
            class="mx-2"
            :items="year_items"
            item-value="id"
            item-text="month_of"
            label="Month Of"
            required
            :rules="rules.combobox_rule"
            dense
            @change="selected_month"
            outlined
          ></v-select>
        </v-col>
        <v-col
          md="3"
          cols="12"
        >
          <v-select
            outlined
            class="mx-2"
            v-model="category_id"
            dense
            label="Category"
            :items="category_items"
            item-value="id"
            item-text="category"
            :rules="rules.combobox_rule"
            @change="selected_month"
          ></v-select>
        </v-col>
      </v-row>
      <v-data-table dense
                    :headers="headers"
                    :items="payroll_data"
                    :search="search"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Data</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearch"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <td>
              {{ item.name
              }}
            </td>
            <td>
              {{ item.details.tin_no
              }}
            </td>
            <td>
              {{ item.details.employee_position.position
              }}
            </td>
            <td>
              {{ item.details.branch.branch_code
              }}
            </td>
            <td>
              {{ duration_counter(item.details.date_of_employment) }}
            </td>
            <td>
              {{ item.rice_allowances
              }}
            </td>
            <td>
              {{ item.holiday_pay
              }}
            </td>
            <td>
              {{ item.gross_salary
              }}
            </td>
            <td>
              {{ item.net_salary
              }}
            </td>
            <td>
              {{ item.deduction_sss
              }}
            </td>
            <td>
              {{ item.deduction_philhealth
              }}
            </td>
            <td>
              {{ item.deduction_pagibig
              }}
            </td>
            <td>
              {{ item.deduction_tax
              }}
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {
    mdiAccountOutline,
    mdiAccountCashOutline,
    mdiInformationOutline,
    mdiAccountSearch
  } from "@mdi/js";
  import moment from "moment";
  import {mapActions, mapGetters} from "vuex";
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      selection: '',
      status: '',
      year: '',
      year_items: [],
      payroll_data: [],
      duration: '',
      sorted_by: 'Name',
      bank_type: 'All',
      bank_type_items: [],
      position: 'All',
      position_items: [],
      batch_no: '',
      batch_no_items: [],
      category_id: '',
      category_items: [],
      search: '',
      headers: [
        {text: 'Name', value: 'name', sortable: false},
        {text: 'TIN', value: 'tin', sortable: false},
        {text: 'Position', value: 'employee.last_name', sortable: false},
        {text: 'Branch', value: 'amount', sortable: false},
        {text: 'Employment Duration', value: 'amount', sortable: false},
        {text: 'Rice Allowances', value: 'bank_code_name', sortable: false},
        {text: 'Holiday Pay', value: 'bank_code_name', sortable: false},
        {text: 'Gross', value: 'bank_code_name', sortable: false},
        {text: 'Net', value: 'bank_code_name', sortable: false},
        {text: 'SSS', value: 'bank_code_name', sortable: false},
        {text: 'Phil-Health', value: 'bank_code_name', sortable: false},
        {text: 'Pag-Ibig', value: 'bank_code_name', sortable: false},
        {text: 'Tax', value: 'bank_code_name', sortable: false},
      ],
    }
  }
  export default {
    components: {
      snackBarDialog,
    },
    setup() {
      return {
        icons: {
          mdiAccountOutline,
          mdiAccountCashOutline,
          mdiInformationOutline,
          mdiAccountSearch,
        },
        is_deleting: false,
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text', 'company_logo']),
    },
    methods: {
      ...mapActions('system_data', ['change_snackbar']),
      ...mapActions('salaries_and_wages', ['initialize_salaries_employee',]),
      ...mapActions('employee', ['bir_monthly_gov',]),
      initialize_data() {
        this.initialize_salaries_employee()
          .then(response => {
            this.year_items = response.data[0].month_of
            this.category_items = response.data[0].category
          })
          .catch(error => {
            console.log(error)
          })
      },
      duration_counter(value) {
        var a = moment();
        var b = moment(value, 'YYYY-MM-DD');

        var years = a.diff(b, 'year');
        b.add(years, 'years');

        var months = a.diff(b, 'months');
        b.add(months, 'months');

        var days = a.diff(b, 'days');
        return years + ' years ' + months + ' months ' + days + ' days'
      },
      date_format(value) {
        return moment(value)
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      },
      selected_month() {
        const data = new FormData()
        data.append('month_of_id', this.year);
        data.append('category_id', this.category_id);
        this.bir_monthly_gov(data)
          .then(response => {
            this.payroll_data = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
  }
</script>
