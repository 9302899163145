import axios from 'axios'
import store from "@/store";

axios.defaults.withCredentials = true

const state = {
  authenticated: false,
  already_generate_salary_scheme: false,
  need_change_password: false,
  is_can_evaluate: false,
  employee_category_id: 0,
  employee_branch_id: 0,
  employee_branch_code: 0,
  employee_branch_data: {},

  user_pic: '',

  user_id: 0,
  is_head: false,
  employee_id: 0,
  id_no: 'CHAP-0-0000',
  name: 'NA',
  name_credit: 'NA',
  position: 'NA',
  prev_position: 'NA',
  department: 'NA',

  month_of: 'NA',
  year: 'NA',
  address: 'GOODLIFE BUILDING, NATIONAL HIGHWAY, BRGY. SAN JOSE DIGOS CITY DAVAO DEL SUR',
  contact: 'EMAIL: goodlifeaccounting@yahoo.com TEL#: (082)333-1809 & (082)272-6271',

  file_interface: true,
  view_as: true,
  view_department_fund_monitoring: true,
  view_damayan_incentives_monitoring: true,
  input_department_fund_monitoring: true,
  cheque_book: true,
  cheque_book_voucher_supply: true,
  cheque_book_accounting_supply: true,
  file_register: true,
  file_create: true,
  file_create_loan: true,
  file_request: true,
  file_request_list_for_approval: true,
  reports_vouchers_for_approval: true,
  file_search: true,
  view_interface: true,
  view_employee_directory: true,
  view_salary: true,
  view_monitoring: true,
  view_employee_payables: true,
  view_bir: true,
  view_cheque_book: true,
  view_company_receivables: true,
  view_voucher_monitoring: true,
  reports_interface: true,
  reports_bod_annual: true,
  reports_company_receivable: true,
  reports_expenditure: true,
  reports_revenue: true,
  reports_vouchers: true,
  input_interface: true,
  input_bod: true,
  input_business_permit: true,
  input_sec_deposit: true,
  input_payment_receivables: true,
  input_salary: true,
  input_monthly_liquidation: true,
  input_vouchers: true,
  file_register_employee: true,
  file_register_auto_maintenance_details: true,
  file_register_company_inventory: true,
  file_register_bank: true,
  file_register_branch: true,
  file_register_bills_info: true,
  file_register_particulars: true,
  file_register_position: true,
  file_register_affiliate: true,
  file_create_auto: true,
  file_create_personal: true,
  file_request_regular: true,
  file_request_pdc: true,
  file_request_ftv: true,
  file_create_motor: true,
  file_create_others: true,
  file_create_minimum_wages: true,
  file_search_employee: true,
  file_search_employee_payables: true,
  file_search_vouchers: true,
  file_search_inventory: true,
  view_employee_directory_pending: true,
  view_amd_monitoring: true,
  view_employee_directory_list: true,
  view_employee_directory_salary: true,
  view_employee_directory_branch: true,
  view_salary_payroll: true,
  view_salary_payslip: true,
  view_salary_monthpa: true,
  view_salary_monthpayslip: true,
  view_salary_monthpamonitorng: true,
  view_bank_transaction: true,
  view_monitoring_business: true,
  view_bir_alpha: true,
  view_bir_month: true,
  view_cheque_book_all: true,
  view_cheque_book_supply: true,
  view_cheque_book_cancled: true,
  view_cheque_book_month: true,
  view_company_receivables_auto: true,
  view_company_receivables_ca: true,
  view_company_receivables_loan: true,
  view_company_receivables_others: true,
  view_company_receivables_motor: true,
  view_company_receivables_bod: true,
  view_voucher_monitoring_cv: true,
  view_voucher_monitoring_pdc: true,
  bank_trial_and_balance: true,
  view_voucher_monitoring_ftv: true,
  reports_bod_annual_dam: true,
  reports_bod_annual_chap: true,
  reports_company_receivable_auto: true,
  reports_company_receivable_ca: true,
  reports_company_receivable_loan: true,
  reports_company_receivable_others: true,
  reports_company_receivable_motor: true,
  reports_company_receivable_bod: true,
  reports_expenditure_annual: true,
  reports_expenditure_annual_monitoring: true,
  reports_expenditure_month: true,
  reports_expenditure_month_pdc: true,
  reports_expenditure_month_auto_deb: true,
  reports_revenue_annual: true,
  reports_revenue_annual_trust: true,
  reports_revenue_monthly: true,
  reports_revenue_monthly_dam: true,
  reports_pre_liquidation: true,
  reports_vouchers_cv: true,
  reports_vouchers_ckv: true,
  reports_vouchers_ftv: true,
  input_bod_month: true,
  input_business_permit_released: true,
  input_business_permit_liquidation: true,
  input_business_permit_return: true,
  input_cash_flow: true,
  input_sec_deposit_office: true,
  input_payment_receivables_auto: true,
  input_payment_receivables_ca: true,
  input_payment_receivables_loan: true,
  input_payment_receivables_others: true,
  input_payment_receivables_motors: true,
  input_payment_receivables_bod: true,
  input_deposit_slip: true,
  input_salary_deductions: true,
  input_salary_data: true,
  input_salary_monthpa: true,
  input_monthly_liquidation_reg: true,
  input_monthly_liquidation_ca: true,
  input_monthly_liquidation_department: true,
  input_vouchers_cv: true,
  input_vouchers_ckv: true,
  input_vouchers_ftv: true,
  input_voucher_maintenance: true,
  input_maintenance: true,
  input_liquidations_maintenance: true,
  load_interface: true,
  load_interface_stocks: true,
  load_interface_stocks_equipment: true,
  load_interface_stocks_car: true,
  file_request_inventory: true,
  view_inventory_monitoring: true,
  view_inventory_monitoring_building: true,
  view_inventory_monitoring_land: true,
  view_inventory_monitoring_car: true,
  view_inventory_monitoring_equipment: true,
  file_register_user: true,
  file_register_tie_up: true,
  view_auto_maintenance_monitoring: true,
  view_employee_directory_pending_salary: true,
  view_billing_monitoring: true,
  reports_revenue_daily_deposited_bank: true,
  input_monthly_liquidation_damayan_rf: true,
  input_monthly_liquidation_chapel_rf: true,
  board_monitoring: true,
  send_memo_bulk: true,
  list_of_bank_depository: true,
}

const mutations = {
  set_authenticated(state, authenticated) {
    state.authenticated = authenticated
  },
  set_already_generate_salary_scheme(state, already_generate_salary_scheme) {
    state.already_generate_salary_scheme = already_generate_salary_scheme
  },

  set_user_pic(state, user_pic) {
    state.user_pic = user_pic
  },
  set_employee_category_id(state, employee_category_id) {
    state.employee_category_id = employee_category_id
  },
  set_employee_branch_data(state, employee_branch_data) {
    state.employee_branch_data = employee_branch_data
  },
  set_employee_branch_id(state, employee_branch_id) {
    state.employee_branch_id = employee_branch_id
  },
  set_employee_branch_code(state, employee_branch_code) {
    state.employee_branch_code = employee_branch_code
  },
  set_need_change_password(state, need_change_password) {
    state.need_change_password = need_change_password
  },
  set_is_can_evaluate(state, is_can_evaluate) {
    state.is_can_evaluate = is_can_evaluate
  },

  set_is_head(state, is_head) {
    state.is_head = is_head
  },

  set_user_id(state, user_id) {
    state.user_id = user_id
  },
  set_employee_id(state, employee_id) {
    state.employee_id = employee_id
  },
  set_id_no(state, id_no) {
    state.id_no = id_no
  },
  set_name(state, name) {
    state.name = name
  },
  set_name_credit(state, name_credit) {
    state.name_credit = name_credit
  },
  set_position(state, position) {
    state.position = position
  },

  set_month_of(state, month_of) {
    state.month_of = month_of
  },
  set_year(state, year) {
    state.year = year
  },

  set_file_interface(state, file_interface) {
    state.file_interface = file_interface
  },
  set_cheque_book(state, cheque_book) {
    state.cheque_book = cheque_book
  },
  set_cheque_book_voucher_supply(state, cheque_book_voucher_supply) {
    state.cheque_book_voucher_supply = cheque_book_voucher_supply
  },
  set_cheque_book_accounting_supply(state, cheque_book_accounting_supply) {
    state.cheque_book_accounting_supply = cheque_book_accounting_supply
  },
  set_file_register(state, file_register) {
    state.file_register = file_register
  },
  set_file_create(state, file_create) {
    state.file_create = file_create
  },
  set_file_create_loan(state, file_create_loan) {
    state.file_create_loan = file_create_loan
  },
  set_file_request(state, file_request) {
    state.file_request = file_request
  },
  set_file_request_list_for_approval(state, file_request_list_for_approval) {
    state.file_request_list_for_approval = file_request_list_for_approval
  },
  set_reports_vouchers_for_approval(state, reports_vouchers_for_approval) {
    state.reports_vouchers_for_approval = reports_vouchers_for_approval
  },
  set_file_search(state, file_search) {
    state.file_search = file_search
  },
  set_view_interface(state, view_interface) {
    state.view_interface = view_interface
  },
  set_view_employee_directory(state, view_employee_directory) {
    state.view_employee_directory = view_employee_directory
  },
  set_view_salary(state, view_salary) {
    state.view_salary = view_salary
  },
  set_view_monitoring(state, view_monitoring) {
    state.view_monitoring = view_monitoring
  },
  set_view_employee_payables(state, view_employee_payables) {
    state.view_employee_payables = view_employee_payables
  },
  set_view_bir(state, view_bir) {
    state.view_bir = view_bir
  },
  set_view_cheque_book(state, view_cheque_book) {
    state.view_cheque_book = view_cheque_book
  },
  set_view_company_receivables(state, view_company_receivables) {
    state.view_company_receivables = view_company_receivables
  },
  set_view_voucher_monitoring(state, view_voucher_monitoring) {
    state.view_voucher_monitoring = view_voucher_monitoring
  },
  set_reports_interface(state, reports_interface) {
    state.reports_interface = reports_interface
  },
  set_reports_bod_annual(state, reports_bod_annual) {
    state.reports_bod_annual = reports_bod_annual
  },
  set_reports_company_receivable(state, reports_company_receivable) {
    state.reports_company_receivable = reports_company_receivable
  },
  set_reports_expenditure(state, reports_expenditure) {
    state.reports_expenditure = reports_expenditure
  },
  set_reports_revenue(state, reports_revenue) {
    state.reports_revenue = reports_revenue
  },
  set_reports_vouchers(state, reports_vouchers) {
    state.reports_vouchers = reports_vouchers
  },
  set_input_interface(state, input_interface) {
    state.input_interface = input_interface
  },
  set_input_bod(state, input_bod) {
    state.input_bod = input_bod
  },
  set_input_business_permit(state, input_business_permit) {
    state.input_business_permit = input_business_permit
  },
  set_input_sec_deposit(state, input_sec_deposit) {
    state.input_sec_deposit = input_sec_deposit
  },
  set_input_payment_receivables(state, input_payment_receivables) {
    state.input_payment_receivables = input_payment_receivables
  },
  set_input_salary(state, input_salary) {
    state.input_salary = input_salary
  },
  set_input_monthly_liquidation(state, input_monthly_liquidation) {
    state.input_monthly_liquidation = input_monthly_liquidation
  },
  set_input_vouchers(state, input_vouchers) {
    state.input_vouchers = input_vouchers
  },
  set_file_register_employee(state, file_register_employee) {
    state.file_register_employee = file_register_employee
  },
  set_file_register_auto_maintenance_details(state, file_register_auto_maintenance_details) {
    state.file_register_auto_maintenance_details = file_register_auto_maintenance_details
  },
  set_file_register_company_inventory(state, file_register_company_inventory) {
    state.file_register_company_inventory = file_register_company_inventory
  },
  set_file_register_bank(state, file_register_bank) {
    state.file_register_bank = file_register_bank
  },
  set_file_register_branch(state, file_register_branch) {
    state.file_register_branch = file_register_branch
  },
  set_file_register_bills_info(state, file_register_bills_info) {
    state.file_register_bills_info = file_register_bills_info
  },
  set_file_register_particulars(state, file_register_particulars) {
    state.file_register_particulars = file_register_particulars
  },
  set_file_register_position(state, file_register_position) {
    state.file_register_position = file_register_position
  },
  set_file_register_affiliate(state, file_register_affiliate) {
    state.file_register_affiliate = file_register_affiliate
  },
  set_file_create_auto(state, file_create_auto) {
    state.file_create_auto = file_create_auto
  },
  set_file_create_personal(state, file_create_personal) {
    state.file_create_personal = file_create_personal
  },
  set_file_request_regular(state, file_request_regular) {
    state.file_request_regular = file_request_regular
  },
  set_file_request_pdc(state, file_request_pdc) {
    state.file_request_pdc = file_request_pdc
  },
  set_file_request_ftv(state, file_request_ftv) {
    state.file_request_ftv = file_request_ftv
  },
  set_file_create_motor(state, file_create_motor) {
    state.file_create_motor = file_create_motor
  },
  set_file_create_others(state, file_create_others) {
    state.file_create_others = file_create_others
  },
  set_file_create_minimum_wages(state, file_create_minimum_wages) {
    state.file_create_minimum_wages = file_create_minimum_wages
  },
  set_file_search_employee(state, file_search_employee) {
    state.file_search_employee = file_search_employee
  },
  set_file_search_employee_payables(state, file_search_employee_payables) {
    state.file_search_employee_payables = file_search_employee_payables
  },
  set_file_search_vouchers(state, file_search_vouchers) {
    state.file_search_vouchers = file_search_vouchers
  },
  set_file_search_inventory(state, file_search_inventory) {
    state.file_search_inventory = file_search_inventory
  },
  set_view_employee_directory_pending(state, view_employee_directory_pending) {
    state.view_employee_directory_pending = view_employee_directory_pending
  },
  set_view_amd_monitoring(state, view_amd_monitoring) {
    state.view_amd_monitoring = view_amd_monitoring
  },
  set_view_employee_directory_list(state, view_employee_directory_list) {
    state.view_employee_directory_list = view_employee_directory_list
  },
  set_view_employee_directory_salary(state, view_employee_directory_salary) {
    state.view_employee_directory_salary = view_employee_directory_salary
  },
  set_view_employee_directory_branch(state, view_employee_directory_branch) {
    state.view_employee_directory_branch = view_employee_directory_branch
  },
  set_view_salary_payroll(state, view_salary_payroll) {
    state.view_salary_payroll = view_salary_payroll
  },
  set_view_salary_payslip(state, view_salary_payslip) {
    state.view_salary_payslip = view_salary_payslip
  },
  set_view_salary_monthpa(state, view_salary_monthpa) {
    state.view_salary_monthpa = view_salary_monthpa
  },
  set_view_salary_monthpayslip(state, view_salary_monthpayslip) {
    state.view_salary_monthpayslip = view_salary_monthpayslip
  },
  set_view_salary_monthpamonitorng(state, view_salary_monthpamonitorng) {
    state.view_salary_monthpamonitorng = view_salary_monthpamonitorng
  },
  set_view_bank_transaction(state, view_bank_transaction) {
    state.view_bank_transaction = view_bank_transaction
  },
  set_view_monitoring_business(state, view_monitoring_business) {
    state.view_monitoring_business = view_monitoring_business
  },
  set_view_bir_alpha(state, view_bir_alpha) {
    state.view_bir_alpha = view_bir_alpha
  },
  set_view_bir_month(state, view_bir_month) {
    state.view_bir_month = view_bir_month
  },
  set_view_cheque_book_all(state, view_cheque_book_all) {
    state.view_cheque_book_all = view_cheque_book_all
  },
  set_view_cheque_book_supply(state, view_cheque_book_supply) {
    state.view_cheque_book_supply = view_cheque_book_supply
  },
  set_view_cheque_book_cancled(state, view_cheque_book_cancled) {
    state.view_cheque_book_cancled = view_cheque_book_cancled
  },
  set_view_cheque_book_month(state, view_cheque_book_month) {
    state.view_cheque_book_month = view_cheque_book_month
  },
  set_view_company_receivables_auto(state, view_company_receivables_auto) {
    state.view_company_receivables_auto = view_company_receivables_auto
  },
  set_view_company_receivables_ca(state, view_company_receivables_ca) {
    state.view_company_receivables_ca = view_company_receivables_ca
  },
  set_view_company_receivables_loan(state, view_company_receivables_loan) {
    state.view_company_receivables_loan = view_company_receivables_loan
  },
  set_view_company_receivables_others(state, view_company_receivables_others) {
    state.view_company_receivables_others = view_company_receivables_others
  },
  set_view_company_receivables_motor(state, view_company_receivables_motor) {
    state.view_company_receivables_motor = view_company_receivables_motor
  },
  set_view_company_receivables_bod(state, view_company_receivables_bod) {
    state.view_company_receivables_bod = view_company_receivables_bod
  },
  set_view_voucher_monitoring_cv(state, view_voucher_monitoring_cv) {
    state.view_voucher_monitoring_cv = view_voucher_monitoring_cv
  },
  set_view_voucher_monitoring_pdc(state, view_voucher_monitoring_pdc) {
    state.view_voucher_monitoring_pdc = view_voucher_monitoring_pdc
  },
  set_bank_trial_and_balance(state, bank_trial_and_balance) {
    state.bank_trial_and_balance = bank_trial_and_balance
  },
  set_view_voucher_monitoring_ftv(state, view_voucher_monitoring_ftv) {
    state.view_voucher_monitoring_ftv = view_voucher_monitoring_ftv
  },
  set_reports_bod_annual_dam(state, reports_bod_annual_dam) {
    state.reports_bod_annual_dam = reports_bod_annual_dam
  },
  set_reports_bod_annual_chap(state, reports_bod_annual_chap) {
    state.reports_bod_annual_chap = reports_bod_annual_chap
  },
  set_reports_company_receivable_auto(state, reports_company_receivable_auto) {
    state.reports_company_receivable_auto = reports_company_receivable_auto
  },
  set_reports_company_receivable_ca(state, reports_company_receivable_ca) {
    state.reports_company_receivable_ca = reports_company_receivable_ca
  },
  set_reports_company_receivable_loan(state, reports_company_receivable_loan) {
    state.reports_company_receivable_loan = reports_company_receivable_loan
  },
  set_reports_company_receivable_others(state, reports_company_receivable_others) {
    state.reports_company_receivable_others = reports_company_receivable_others
  },
  set_reports_company_receivable_motor(state, reports_company_receivable_motor) {
    state.reports_company_receivable_motor = reports_company_receivable_motor
  },
  set_reports_company_receivable_bod(state, reports_company_receivable_bod) {
    state.reports_company_receivable_bod = reports_company_receivable_bod
  },
  set_reports_expenditure_annual(state, reports_expenditure_annual) {
    state.reports_expenditure_annual = reports_expenditure_annual
  },
  set_reports_expenditure_annual_monitoring(state, reports_expenditure_annual_monitoring) {
    state.reports_expenditure_annual_monitoring = reports_expenditure_annual_monitoring
  },
  set_reports_expenditure_month(state, reports_expenditure_month) {
    state.reports_expenditure_month = reports_expenditure_month
  },
  set_reports_expenditure_month_pdc(state, reports_expenditure_month_pdc) {
    state.reports_expenditure_month_pdc = reports_expenditure_month_pdc
  },
  set_reports_expenditure_month_auto_deb(state, reports_expenditure_month_auto_deb) {
    state.reports_expenditure_month_auto_deb = reports_expenditure_month_auto_deb
  },
  set_reports_revenue_annual(state, reports_revenue_annual) {
    state.reports_revenue_annual = reports_revenue_annual
  },
  set_reports_revenue_annual_trust(state, reports_revenue_annual_trust) {
    state.reports_revenue_annual_trust = reports_revenue_annual_trust
  },
  set_reports_revenue_monthly(state, reports_revenue_monthly) {
    state.reports_revenue_monthly = reports_revenue_monthly
  },
  set_reports_revenue_monthly_dam(state, reports_revenue_monthly_dam) {
    state.reports_revenue_monthly_dam = reports_revenue_monthly_dam
  },
  set_reports_pre_liquidation(state, reports_pre_liquidation) {
    state.reports_pre_liquidation = reports_pre_liquidation
  },
  set_reports_vouchers_cv(state, reports_vouchers_cv) {
    state.reports_vouchers_cv = reports_vouchers_cv
  },
  set_reports_vouchers_ckv(state, reports_vouchers_ckv) {
    state.reports_vouchers_ckv = reports_vouchers_ckv
  },
  set_reports_vouchers_ftv(state, reports_vouchers_ftv) {
    state.reports_vouchers_ftv = reports_vouchers_ftv
  },
  set_input_bod_month(state, input_bod_month) {
    state.input_bod_month = input_bod_month
  },
  set_input_business_permit_released(state, input_business_permit_released) {
    state.input_business_permit_released = input_business_permit_released
  },
  set_input_business_permit_liquidation(state, input_business_permit_liquidation) {
    state.input_business_permit_liquidation = input_business_permit_liquidation
  },
  set_input_business_permit_return(state, input_business_permit_return) {
    state.input_business_permit_return = input_business_permit_return
  },
  set_input_cash_flow(state, input_cash_flow) {
    state.input_cash_flow = input_cash_flow
  },
  set_input_sec_deposit_office(state, input_sec_deposit_office) {
    state.input_sec_deposit_office = input_sec_deposit_office
  },
  set_input_payment_receivables_auto(state, input_payment_receivables_auto) {
    state.input_payment_receivables_auto = input_payment_receivables_auto
  },
  set_input_payment_receivables_ca(state, input_payment_receivables_ca) {
    state.input_payment_receivables_ca = input_payment_receivables_ca
  },
  set_input_payment_receivables_loan(state, input_payment_receivables_loan) {
    state.input_payment_receivables_loan = input_payment_receivables_loan
  },
  set_input_payment_receivables_others(state, input_payment_receivables_others) {
    state.input_payment_receivables_others = input_payment_receivables_others
  },
  set_input_payment_receivables_motors(state, input_payment_receivables_motors) {
    state.input_payment_receivables_motors = input_payment_receivables_motors
  },
  set_input_payment_receivables_bod(state, input_payment_receivables_bod) {
    state.input_payment_receivables_bod = input_payment_receivables_bod
  },
  set_input_deposit_slip(state, input_deposit_slip) {
    state.input_deposit_slip = input_deposit_slip
  },
  set_input_salary_deductions(state, input_salary_deductions) {
    state.input_salary_deductions = input_salary_deductions
  },
  set_input_salary_data(state, input_salary_data) {
    state.input_salary_data = input_salary_data
  },
  set_input_salary_monthpa(state, input_salary_monthpa) {
    state.input_salary_monthpa = input_salary_monthpa
  },
  set_input_monthly_liquidation_reg(state, input_monthly_liquidation_reg) {
    state.input_monthly_liquidation_reg = input_monthly_liquidation_reg
  },
  set_input_monthly_liquidation_ca(state, input_monthly_liquidation_ca) {
    state.input_monthly_liquidation_ca = input_monthly_liquidation_ca
  },
  set_input_monthly_liquidation_department(state, input_monthly_liquidation_department) {
    state.input_monthly_liquidation_department = input_monthly_liquidation_department
  },
  set_input_vouchers_cv(state, input_vouchers_cv) {
    state.input_vouchers_cv = input_vouchers_cv
  },
  set_input_vouchers_ckv(state, input_vouchers_ckv) {
    state.input_vouchers_ckv = input_vouchers_ckv
  },
  set_input_vouchers_ftv(state, input_vouchers_ftv) {
    state.input_vouchers_ftv = input_vouchers_ftv
  },
  set_input_voucher_maintenance(state, input_voucher_maintenance) {
    state.input_voucher_maintenance = input_voucher_maintenance
  },
  set_input_maintenance(state, input_maintenance) {
    state.input_maintenance = input_maintenance
  },
  set_input_liquidations_maintenance(state, input_liquidations_maintenance) {
    state.input_liquidations_maintenance = input_liquidations_maintenance
  },
  set_load_interface(state, load_interface) {
    state.load_interface = load_interface
  },
  set_load_interface_stocks(state, load_interface_stocks) {
    state.load_interface_stocks = load_interface_stocks
  },
  set_load_interface_stocks_equipment(state, load_interface_stocks_equipment) {
    state.load_interface_stocks_equipment = load_interface_stocks_equipment
  },
  set_load_interface_stocks_car(state, load_interface_stocks_car) {
    state.load_interface_stocks_car = load_interface_stocks_car
  },
  set_file_request_inventory(state, file_request_inventory) {
    state.file_request_inventory = file_request_inventory
  },
  set_view_inventory_monitoring(state, view_inventory_monitoring) {
    state.view_inventory_monitoring = view_inventory_monitoring
  },
  set_view_inventory_monitoring_building(state, view_inventory_monitoring_building) {
    state.view_inventory_monitoring_building = view_inventory_monitoring_building
  },
  set_view_inventory_monitoring_land(state, view_inventory_monitoring_land) {
    state.view_inventory_monitoring_land = view_inventory_monitoring_land
  },
  set_view_inventory_monitoring_car(state, view_inventory_monitoring_car) {
    state.view_inventory_monitoring_car = view_inventory_monitoring_car
  },
  set_view_inventory_monitoring_equipment(state, view_inventory_monitoring_equipment) {
    state.view_inventory_monitoring_equipment = view_inventory_monitoring_equipment
  },
  set_file_register_user(state, file_register_user) {
    state.file_register_user = file_register_user
  },
  set_file_register_tie_up(state, file_register_tie_up) {
    state.file_register_tie_up = file_register_tie_up
  },
  set_view_auto_maintenance_monitoring(state, view_auto_maintenance_monitoring) {
    state.view_auto_maintenance_monitoring = view_auto_maintenance_monitoring
  },
  set_view_employee_directory_pending_salary(state, view_employee_directory_pending_salary) {
    state.view_employee_directory_pending_salary = view_employee_directory_pending_salary
  },
  set_view_billing_monitoring(state, view_billing_monitoring) {
    state.view_billing_monitoring = view_billing_monitoring
  },
  set_reports_revenue_daily_deposited_bank(state, reports_revenue_daily_deposited_bank) {
    state.reports_revenue_daily_deposited_bank = reports_revenue_daily_deposited_bank
  },
  set_input_monthly_liquidation_damayan_rf(state, input_monthly_liquidation_damayan_rf) {
    state.input_monthly_liquidation_damayan_rf = input_monthly_liquidation_damayan_rf
  },
  set_input_monthly_liquidation_chapel_rf(state, input_monthly_liquidation_chapel_rf) {
    state.input_monthly_liquidation_chapel_rf = input_monthly_liquidation_chapel_rf
  },
  set_board_monitoring(state, board_monitoring) {
    state.board_monitoring = board_monitoring
  },
  set_send_memo_bulk(state, send_memo_bulk) {
    state.send_memo_bulk = send_memo_bulk
  },
  set_list_of_bank_depository(state, list_of_bank_depository) {
    state.list_of_bank_depository = list_of_bank_depository
  },
  set_view_as(state, view_as) {
    state.view_as = view_as
  },
  set_view_department_fund_monitoring(state, view_department_fund_monitoring) {
    state.view_department_fund_monitoring = view_department_fund_monitoring
  },
  set_view_damayan_incentives_monitoring(state, view_damayan_incentives_monitoring) {
    state.view_damayan_incentives_monitoring = view_damayan_incentives_monitoring
  },
  set_input_department_fund_monitoring(state, input_department_fund_monitoring) {
    state.input_department_fund_monitoring = input_department_fund_monitoring
  },
  set_prev_position(state, prev_position) {
    state.prev_position = prev_position
  },
}

const getters = {
  authenticated: state => state.authenticated,
  already_generate_salary_scheme: state => state.already_generate_salary_scheme,
  user_pic: state => state.user_pic,
  employee_category_id: state => state.employee_category_id,
  employee_branch_id: state => state.employee_branch_id,
  employee_branch_code: state => state.employee_branch_code,
  employee_branch_data: state => state.employee_branch_data,
  need_change_password: state => state.need_change_password,
  is_can_evaluate: state => state.is_can_evaluate,
  prev_position: state => state.prev_position,

  is_head: state => state.is_head,

  user_id: state => state.user_id,
  employee_id: state => state.employee_id,
  id_no: state => state.id_no,
  position: state => state.position,
  name: state => state.name,
  name_credit: state => state.name_credit,

  month_of: state => state.month_of,
  year: state => state.year,

  address: state => state.address,
  contact: state => state.contact,
  department: state => state.department,

  file_interface: state => state.file_interface,
  cheque_book: state => state.cheque_book,
  cheque_book_voucher_supply: state => state.cheque_book_voucher_supply,
  cheque_book_accounting_supply: state => state.cheque_book_accounting_supply,
  file_register: state => state.file_register,
  file_create: state => state.file_create,
  file_create_loan: state => state.file_create_loan,
  file_request: state => state.file_request,
  file_request_list_for_approval: state => state.file_request_list_for_approval,
  reports_vouchers_for_approval: state => state.reports_vouchers_for_approval,
  file_search: state => state.file_search,
  view_interface: state => state.view_interface,
  view_employee_directory: state => state.view_employee_directory,
  view_salary: state => state.view_salary,
  view_monitoring: state => state.view_monitoring,
  view_employee_payables: state => state.view_employee_payables,
  view_bir: state => state.view_bir,
  view_cheque_book: state => state.view_cheque_book,
  view_company_receivables: state => state.view_company_receivables,
  view_voucher_monitoring: state => state.view_voucher_monitoring,
  reports_interface: state => state.reports_interface,
  reports_bod_annual: state => state.reports_bod_annual,
  reports_company_receivable: state => state.reports_company_receivable,
  reports_expenditure: state => state.reports_expenditure,
  reports_revenue: state => state.reports_revenue,
  reports_vouchers: state => state.reports_vouchers,
  input_interface: state => state.input_interface,
  input_bod: state => state.input_bod,
  input_business_permit: state => state.input_business_permit,
  input_sec_deposit: state => state.input_sec_deposit,
  input_payment_receivables: state => state.input_payment_receivables,
  input_salary: state => state.input_salary,
  input_monthly_liquidation: state => state.input_monthly_liquidation,
  input_vouchers: state => state.input_vouchers,
  file_register_employee: state => state.file_register_employee,
  file_register_auto_maintenance_details: state => state.file_register_auto_maintenance_details,
  file_register_company_inventory: state => state.file_register_company_inventory,
  file_register_bank: state => state.file_register_bank,
  file_register_branch: state => state.file_register_branch,
  file_register_bills_info: state => state.file_register_bills_info,
  file_register_particulars: state => state.file_register_particulars,
  file_register_position: state => state.file_register_position,
  file_register_affiliate: state => state.file_register_affiliate,
  file_create_auto: state => state.file_create_auto,
  file_create_personal: state => state.file_create_personal,
  file_request_regular: state => state.file_request_regular,
  file_request_pdc: state => state.file_request_pdc,
  file_request_ftv: state => state.file_request_ftv,
  file_create_motor: state => state.file_create_motor,
  file_create_others: state => state.file_create_others,
  file_create_minimum_wages: state => state.file_create_minimum_wages,
  file_search_employee: state => state.file_search_employee,
  file_search_employee_payables: state => state.file_search_employee_payables,
  file_search_vouchers: state => state.file_search_vouchers,
  file_search_inventory: state => state.file_search_inventory,
  view_employee_directory_pending: state => state.view_employee_directory_pending,
  view_amd_monitoring: state => state.view_amd_monitoring,
  view_employee_directory_list: state => state.view_employee_directory_list,
  view_employee_directory_salary: state => state.view_employee_directory_salary,
  view_employee_directory_branch: state => state.view_employee_directory_branch,
  view_salary_payroll: state => state.view_salary_payroll,
  view_salary_payslip: state => state.view_salary_payslip,
  view_salary_monthpa: state => state.view_salary_monthpa,
  view_salary_monthpayslip: state => state.view_salary_monthpayslip,
  view_salary_monthpamonitorng: state => state.view_salary_monthpamonitorng,
  view_bank_transaction: state => state.view_bank_transaction,
  view_monitoring_business: state => state.view_monitoring_business,
  view_bir_alpha: state => state.view_bir_alpha,
  view_bir_month: state => state.view_bir_month,
  view_cheque_book_all: state => state.view_cheque_book_all,
  view_cheque_book_supply: state => state.view_cheque_book_supply,
  view_cheque_book_cancled: state => state.view_cheque_book_cancled,
  view_cheque_book_month: state => state.view_cheque_book_month,
  view_company_receivables_auto: state => state.view_company_receivables_auto,
  view_company_receivables_ca: state => state.view_company_receivables_ca,
  view_company_receivables_loan: state => state.view_company_receivables_loan,
  view_company_receivables_others: state => state.view_company_receivables_others,
  view_company_receivables_motor: state => state.view_company_receivables_motor,
  view_company_receivables_bod: state => state.view_company_receivables_bod,
  view_voucher_monitoring_cv: state => state.view_voucher_monitoring_cv,
  view_voucher_monitoring_pdc: state => state.view_voucher_monitoring_pdc,
  bank_trial_and_balance: state => state.bank_trial_and_balance,
  view_voucher_monitoring_ftv: state => state.view_voucher_monitoring_ftv,
  reports_bod_annual_dam: state => state.reports_bod_annual_dam,
  reports_bod_annual_chap: state => state.reports_bod_annual_chap,
  reports_company_receivable_auto: state => state.reports_company_receivable_auto,
  reports_company_receivable_ca: state => state.reports_company_receivable_ca,
  reports_company_receivable_loan: state => state.reports_company_receivable_loan,
  reports_company_receivable_others: state => state.reports_company_receivable_others,
  reports_company_receivable_motor: state => state.reports_company_receivable_motor,
  reports_company_receivable_bod: state => state.reports_company_receivable_bod,
  reports_expenditure_annual: state => state.reports_expenditure_annual,
  reports_expenditure_annual_monitoring: state => state.reports_expenditure_annual_monitoring,
  reports_expenditure_month: state => state.reports_expenditure_month,
  reports_expenditure_month_pdc: state => state.reports_expenditure_month_pdc,
  reports_expenditure_month_auto_deb: state => state.reports_expenditure_month_auto_deb,
  reports_revenue_annual: state => state.reports_revenue_annual,
  reports_revenue_annual_trust: state => state.reports_revenue_annual_trust,
  reports_revenue_monthly: state => state.reports_revenue_monthly,
  reports_revenue_monthly_dam: state => state.reports_revenue_monthly_dam,
  reports_pre_liquidation: state => state.reports_pre_liquidation,
  reports_vouchers_cv: state => state.reports_vouchers_cv,
  reports_vouchers_ckv: state => state.reports_vouchers_ckv,
  reports_vouchers_ftv: state => state.reports_vouchers_ftv,
  input_bod_month: state => state.input_bod_month,
  input_business_permit_released: state => state.input_business_permit_released,
  input_business_permit_liquidation: state => state.input_business_permit_liquidation,
  input_business_permit_return: state => state.input_business_permit_return,
  input_cash_flow: state => state.input_cash_flow,
  input_sec_deposit_office: state => state.input_sec_deposit_office,
  input_payment_receivables_auto: state => state.input_payment_receivables_auto,
  input_payment_receivables_ca: state => state.input_payment_receivables_ca,
  input_payment_receivables_loan: state => state.input_payment_receivables_loan,
  input_payment_receivables_others: state => state.input_payment_receivables_others,
  input_payment_receivables_motors: state => state.input_payment_receivables_motors,
  input_payment_receivables_bod: state => state.input_payment_receivables_bod,
  input_deposit_slip: state => state.input_deposit_slip,
  input_salary_deductions: state => state.input_salary_deductions,
  input_salary_data: state => state.input_salary_data,
  input_salary_monthpa: state => state.input_salary_monthpa,
  input_monthly_liquidation_reg: state => state.input_monthly_liquidation_reg,
  input_monthly_liquidation_ca: state => state.input_monthly_liquidation_ca,
  input_monthly_liquidation_department: state => state.input_monthly_liquidation_department,
  input_vouchers_cv: state => state.input_vouchers_cv,
  input_vouchers_ckv: state => state.input_vouchers_ckv,
  input_vouchers_ftv: state => state.input_vouchers_ftv,
  input_voucher_maintenance: state => state.input_voucher_maintenance,
  input_maintenance: state => state.input_maintenance,
  input_liquidations_maintenance: state => state.input_liquidations_maintenance,
  load_interface: state => state.load_interface,
  load_interface_stocks: state => state.load_interface_stocks,
  load_interface_stocks_equipment: state => state.load_interface_stocks_equipment,
  load_interface_stocks_car: state => state.load_interface_stocks_car,
  file_request_inventory: state => state.file_request_inventory,
  view_inventory_monitoring: state => state.view_inventory_monitoring,
  view_inventory_monitoring_building: state => state.view_inventory_monitoring_building,
  view_inventory_monitoring_land: state => state.view_inventory_monitoring_land,
  view_inventory_monitoring_car: state => state.view_inventory_monitoring_car,
  view_inventory_monitoring_equipment: state => state.view_inventory_monitoring_equipment,
  file_register_user: state => state.file_register_user,
  file_register_tie_up: state => state.file_register_tie_up,
  view_auto_maintenance_monitoring: state => state.view_auto_maintenance_monitoring,
  view_employee_directory_pending_salary: state => state.view_employee_directory_pending_salary,
  view_billing_monitoring: state => state.view_billing_monitoring,
  reports_revenue_daily_deposited_bank: state => state.reports_revenue_daily_deposited_bank,
  input_monthly_liquidation_damayan_rf: state => state.input_monthly_liquidation_damayan_rf,
  input_monthly_liquidation_chapel_rf: state => state.input_monthly_liquidation_chapel_rf,
  board_monitoring: state => state.board_monitoring,
  send_memo_bulk: state => state.send_memo_bulk,
  list_of_bank_depository: state => state.list_of_bank_depository,
  view_as: state => state.view_as,
  view_department_fund_monitoring: state => state.view_department_fund_monitoring,
  view_damayan_incentives_monitoring: state => state.view_damayan_incentives_monitoring,
  input_department_fund_monitoring: state => state.input_department_fund_monitoring,
}
const actions = {
  change_position: ({commit}, data) => {
    commit('set_position', data.position)
    store.dispatch(
      'authentication/pages_striction',
      {root: true})
  },
  login_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .get('/sanctum/csrf-cookie')
        .then(() => {
          axios
            .post('api/login-user-profile', data)
            .then(response => {
              if (response.status === 201) {
                commit('set_authenticated', false)
              } else {
                commit('set_need_change_password', response.data[0].user.is_need_change_password)
                commit('set_is_can_evaluate', response.data[0].is_can_evaluate)
                commit('set_authenticated', true)
                commit('set_user_pic', response.data[0].image)
                commit(
                  'set_name',
                  response.data[0].user.employee.last_name +
                  ', ' +
                  response.data[0].user.employee.first_name +
                  ' ' +
                  response.data[0].user.employee.middle_name,
                )
                commit(
                  'set_name_credit',
                  response.data[0].user.employee.last_name +
                  ', ' +
                  response.data[0].user.employee.first_name,
                )
                commit('set_employee_category_id', response.data[0].user.employee.category_id)
                commit('set_employee_branch_id', response.data[0].user.employee.branch_id)
                commit('set_employee_branch_code', response.data[0].user.employee.branch.branch_code)
                commit('set_user_id', response.data[0].user.id)
                commit('set_employee_id', response.data[0].user.employee_id)
                commit('set_id_no', response.data[0].user.id_no)
                commit('set_position', response.data[0].user.position)
                commit('set_is_head', response.data[0].user.is_head)
                commit('set_employee_branch_data', response.data[0].user.employee.branch)
                commit('set_prev_position', response.data[0].user.position)
                commit('set_already_generate_salary_scheme', false)
                store.dispatch(
                  'authentication/pages_striction',
                  {root: true})
              }
              resolve(response)
            })
            .catch(error => {
              reject(error)
            })
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  pages_striction({commit, rootGetters}) {
    var position = rootGetters['authentication/position']
    var prev_position = rootGetters['authentication/prev_position']
    var employee_category_id = rootGetters['authentication/employee_category_id']
    var employee_id = rootGetters['authentication/employee_id']
    commit(
      'set_view_as',
      prev_position === 'ADMIN'
      ,
    )
    commit(
      'set_view_department_fund_monitoring',
      position === 'ADMIN' || position === 'ACCOUNTING' || position === 'FINANCE'
      ,
    )
    commit(
      'set_view_damayan_incentives_monitoring',
      position === 'ADMIN'
      ,
    )
    commit(
      'set_file_interface',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'AMD' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'EMPLOYEE' ||
      position === 'IT' ||
      position === 'LEGAL' ||
      position === 'CHAPEL AUDIT'
      ,
    )
    commit(
      'set_input_department_fund_monitoring',
      position === 'EMPLOYEE'
      ,
    )
    commit(
      'set_cheque_book',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_cheque_book_voucher_supply',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_cheque_book_accounting_supply',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )

    commit(
      'set_file_register',
      position === 'ADMIN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'AMD' ||position === 'ACCOUNTING' ||  position === 'TREASURY' || position === 'FINANCE'
      ,
    )
    commit('set_file_register_user', position === 'ADMIN')
    commit(
      'set_file_register_auto_maintenance_details',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )
    commit(
      'set_file_register_company_inventory',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )
    commit(
      'set_file_register_employee',
      position === 'ADMIN' || position === 'HUMAN RESOURCE',
    )
    commit('set_file_register_bank', position === 'ADMIN' || position === 'TREASURY')
    commit('set_file_register_branch', position === 'ADMIN')
    commit('set_file_register_bills_info', position === 'ADMIN' || position === 'ACCOUNTING')
    commit('set_file_register_particulars', position === 'ADMIN' || position === 'FINANCE')
    commit('set_file_register_position', position === 'ADMIN' && (employee_id === 1 || employee_id === 2))
    commit('set_file_register_affiliate', position === 'ADMIN')
    commit('set_file_register_tie_up', position === 'ADMIN' || position === 'AMD')

    commit(
      'set_file_request_inventory',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )

    commit(
      'set_file_create',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'AMD' ||
      position === 'BOOKKEEPING' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' || position === 'FINANCE' ||
      position === 'EXECUTIVE'
      ,
    )
    commit('set_file_create_loan', position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE')
    commit('set_file_create_auto', position === 'ADMIN' ||  position === 'AR/AP' || position === 'FINANCE')
    commit('set_file_create_personal', position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE')
    commit('set_file_create_motor', position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE')
    commit('set_file_create_others', position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE')
    commit('set_file_create_minimum_wages', position === 'ADMIN' && (employee_id === 1 || employee_id === 2 || employee_id === 3))

    commit(
      'set_file_search',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING' ||
      position === 'LEGAL' ||
      position === 'PROPERTY CUSTODIAN'
      ,
    )
    commit(
      'set_file_search_employee',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'LEGAL' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_file_search_employee_payables',
      position === 'ADMIN' ||
      position === 'FINANCE' ||
      position === 'AR/AP'
      ,
    )
    commit(
      'set_file_search_vouchers',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'AMD'
      ,
    )
    commit(
      'set_file_search_inventory',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN'
    )

    commit(
      'set_file_request',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'AMD' ||
      position === 'BOOKKEEPING' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' || position === 'FINANCE' ||
      position === 'EXECUTIVE'
      ,
    )
    commit(
      'set_file_request_list_for_approval',
      position === 'ADMIN' && (employee_id === 1 || employee_id === 2 || employee_id === 187)
      ,
    )
    commit(
      'set_reports_vouchers_for_approval',
      position === 'ADMIN' && (employee_id === 1 || employee_id === 2 || employee_id === 3 || employee_id === 187)
      || position === 'TREASURY' && (employee_id === 106 || employee_id === 337 || employee_id === 416)
      ,
    )
    commit(
      'set_file_request_regular',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'AMD' ||
      position === 'BOOKKEEPING' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' || position === 'FINANCE' ||
      position === 'EXECUTIVE'
      ,
    )
    commit(
      'set_file_request_pdc',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'AMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'PROPERTY CUSTODIAN'
      ,
    )
    commit('set_file_request_ftv',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' || position === 'FINANCE'
      ,
    )

    commit(
      'set_view_interface',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'AMD' ||
      position === 'AUDIT'
      ,
    )
    commit(
      'set_view_inventory_monitoring',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN' || position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_view_inventory_monitoring_building',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN' || position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_view_inventory_monitoring_land',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN' || position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_view_inventory_monitoring_car',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN' || position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_view_inventory_monitoring_equipment',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN' || position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_view_auto_maintenance_monitoring',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )

    commit(
      'set_view_employee_directory',
      position === 'ADMIN' ||
      position === 'FINANCE' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_view_employee_directory_pending',
      position === 'ADMIN' || position === 'HUMAN RESOURCE',
    )
    commit(
      'set_view_amd_monitoring',
      position === 'ADMIN' || position === 'AMD',
    )
    commit(
      'set_view_employee_directory_list',
      position === 'ADMIN' ||
      position === 'FINANCE' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING',
    )
    commit('set_view_employee_directory_pending_salary', position === 'ADMIN' || (position === 'HUMAN RESOURCE' && employee_id === 417))
    commit('set_view_billing_monitoring', position === 'ADMIN' || position === 'ACCOUNTING')
    commit('set_reports_revenue_daily_deposited_bank', position === 'ADMIN' || position === 'CMD')
    commit('set_board_monitoring', position === 'ADMIN')
    commit('set_send_memo_bulk', position === 'ADMIN' || position === 'EXECUTIVE')
    commit('set_list_of_bank_depository', position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING')
    commit('set_input_monthly_liquidation_damayan_rf', position === 'ADMIN' || (position === 'EMPLOYEE' && employee_category_id != 3))
    commit('set_input_monthly_liquidation_chapel_rf', position === 'ADMIN' || (position === 'EMPLOYEE' && employee_category_id === 3))
    commit(
      'set_view_employee_directory_salary',
      position === 'ADMIN' || (position === 'HUMAN RESOURCE' && employee_id === 417),
    )
    commit(
      'set_view_employee_directory_branch',
      position === 'ADMIN' || position === 'HUMAN RESOURCE' || position === 'EXECUTIVE',
    )

    commit(
      'set_view_salary',
      position === 'ADMIN' ||
      (position === 'TREASURY') ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'CMD' ||
      position === 'AMD' ||
      position === 'AUDIT' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_view_salary_payroll',
      position === 'ADMIN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD'
      ,
    )
    commit(
      'set_view_salary_payslip',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'AMD' ||
      position === 'AUDIT' ||
      position === 'TREASURY' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_view_salary_monthpa',
      position === 'ADMIN' ||
      (position === 'TREASURY' && (employee_id === 106 || employee_id === 337)) ||
      position === 'HUMAN RESOURCE' || position === 'FINANCE'
      ,
    )
    commit(
      'set_view_salary_monthpayslip',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'AMD' ||
      position === 'AUDIT' ||
      position === 'TREASURY' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_view_salary_monthpamonitorng',
      position === 'ADMIN' || position === 'HUMAN RESOURCE' || position === 'FINANCE',
    )

    commit('set_view_bank_transaction', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE')

    commit(
      'set_view_monitoring',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'AMD' ||
      position === 'AUDIT' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_view_employee_payables',
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'AMD' ||
      position === 'AUDIT' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'BOOKKEEPING'
      ,
    )
    commit(
      'set_view_monitoring_business',
      position === 'ADMIN' || position === 'BOOKKEEPING' || position === 'ACCOUNTING',
    )

    commit('set_view_bir', position === 'ADMIN' || position === 'BOOKKEEPING')
    commit('set_view_bir_alpha', position === 'ADMIN' || position === 'BOOKKEEPING')
    commit('set_view_bir_month', position === 'ADMIN' || position === 'BOOKKEEPING')

    commit(
      'set_view_cheque_book',
      position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD'
      ,
    )
    commit(
      'set_view_cheque_book_all',
      position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD'
      ,
    )
    commit(
      'set_view_cheque_book_supply',
      position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD'
      ,
    )
    commit(
      'set_view_cheque_book_cancled',
      position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD'
      ,
    )
    commit(
      'set_view_cheque_book_month',
      position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD'
      ,
    )

    commit(
      'set_view_company_receivables',
      position === 'ADMIN'|| position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' || position === 'CMD'
      ,
    )
    commit(
      'set_view_company_receivables_auto',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' || position === 'CMD'
      ,
    )
    commit(
      'set_view_company_receivables_ca',
      position === 'ADMIN'  || position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' || position === 'CMD'
      ,
    )
    commit(
      'set_view_company_receivables_loan',
      position === 'ADMIN'  || position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' || position === 'CMD'
      ,
    )
    commit(
      'set_view_company_receivables_others',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' || position === 'CMD'
      ,
    )
    commit(
      'set_view_company_receivables_motor',
      position === 'ADMIN' ||  position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' || position === 'CMD'
      ,
    )
    commit('set_view_company_receivables_bod', position === 'ADMIN')

    commit(
      'set_view_voucher_monitoring',
      position === 'ADMIN' || position === 'CMD' || position === 'TREASURY' || position === 'FINANCE',
    )
    commit(
      'set_view_voucher_monitoring_cv',
      position === 'ADMIN' || position === 'CMD' || position === 'TREASURY' || position === 'FINANCE',
    )
    commit(
      'set_view_voucher_monitoring_pdc',
      position === 'ADMIN' || position === 'CMD' || position === 'TREASURY' || position === 'FINANCE',
    )
    commit(
      'set_bank_trial_and_balance',
      position === 'CMD',
    )
    commit(
      'set_view_voucher_monitoring_ftv',
      position === 'ADMIN' || position === 'CMD' || position === 'TREASURY' || position === 'FINANCE',
    )

    commit(
      'set_reports_interface',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'CMD' ||
      position === 'AMD' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'ACCOUNTING'
      ,
    )
    commit('set_reports_bod_annual', position === 'ADMIN' && (employee_id === 2 || employee_id === 3 || employee_id === 1))
    commit('set_reports_bod_annual_dam', position === 'ADMIN' && (employee_id === 2 || employee_id === 3 || employee_id === 1))
    commit('set_reports_bod_annual_chap', position === 'ADMIN' && (employee_id === 2 || employee_id === 3 || employee_id === 1))

    commit('set_reports_company_receivable', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE' ||
      position === 'AR/AP')
    commit(
      'set_reports_company_receivable_auto',
      position === 'ADMIN' || position === 'CMD' || position === 'FINANCE' ||
      position === 'AR/AP',
    )
    commit(
      'set_reports_company_receivable_ca',
      position === 'ADMIN' || position === 'CMD' || position === 'FINANCE' ||
      position === 'AR/AP' ,
    )
    commit(
      'set_reports_company_receivable_loan',
      position === 'ADMIN' || position === 'CMD' || position === 'FINANCE' ||
      position === 'AR/AP',
    )
    commit(
      'set_reports_company_receivable_others',
      position === 'ADMIN' || position === 'CMD' || position === 'FINANCE' ||
      position === 'AR/AP' ,
    )
    commit(
      'set_reports_company_receivable_motor',
      position === 'ADMIN' || position === 'CMD' || position === 'FINANCE' ||
      position === 'AR/AP' ,
    )
    commit('set_reports_company_receivable_bod', position === 'ADMIN')

    commit(
      'set_reports_expenditure',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_reports_expenditure_annual',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_reports_expenditure_annual_monitoring',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_reports_expenditure_month',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_reports_expenditure_month_pdc',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_reports_expenditure_month_auto_deb',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )

    commit('set_reports_revenue', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE')
    commit('set_reports_revenue_annual', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE')
    commit('set_reports_revenue_annual_trust', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE')
    commit('set_reports_revenue_monthly', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE')
    commit('set_reports_revenue_monthly_dam', position === 'ADMIN' || position === 'CMD')

    commit(
      'set_reports_pre_liquidation',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD' || position === 'TREASURY',
    )

    commit(
      'set_reports_vouchers',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'AMD'
      ,
    )
    commit(
      'set_reports_vouchers_cv',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'CMD' ||
      position === 'AMD'
      ,
    )
    commit(
      'set_reports_vouchers_ckv',
      position === 'ADMIN' ||
      position === 'TREASURY' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'AMD'
      ,
    )
    commit(
      'set_reports_vouchers_ftv',
      position === 'ADMIN' || position === 'TREASURY' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD'
      ,
    )

    commit(
      'set_input_interface',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'AR/AP' ||
      position === 'CMD' ||
      position === 'TREASURY' ||
      position === 'AMD' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'AUDIT'
      ,
    )
    commit('set_input_bod', position === 'ADMIN' && (employee_id === 2 || employee_id === 3 || employee_id === 1))
    commit('set_input_bod_month', position === 'ADMIN' && (employee_id === 2 || employee_id === 3 || employee_id === 1))

    commit('set_input_business_permit', position === 'ADMIN' || position === 'BOOKKEEPING')
    commit(
      'set_input_business_permit_released',
      position === 'ADMIN' || position === 'BOOKKEEPING',
    )
    commit(
      'set_input_business_permit_liquidation',
      position === 'ADMIN' || position === 'BOOKKEEPING',
    )
    commit(
      'set_input_business_permit_return',
      position === 'ADMIN' || position === 'BOOKKEEPING',
    )

    commit('set_input_cash_flow', position === 'ADMIN' || position === 'CMD' || position === 'FINANCE')

    commit('set_input_sec_deposit', position === 'ADMIN' && (employee_id === 1 || employee_id === 2 || employee_id === 187))
    commit('set_input_sec_deposit_office', position === 'ADMIN' && (employee_id === 1 || employee_id === 2 || employee_id === 187))

    commit('set_input_payment_receivables', position === 'ADMIN'  || position === 'AR/AP' || position === 'FINANCE')
    commit(
      'set_input_payment_receivables_auto',
      position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE',
    )
    commit(
      'set_input_payment_receivables_ca',
      position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE',
    )
    commit(
      'set_input_payment_receivables_loan',
      position === 'ADMIN'|| position === 'AR/AP' || position === 'FINANCE',
    )
    commit(
      'set_input_payment_receivables_others',
      position === 'ADMIN'|| position === 'AR/AP' || position === 'FINANCE',
    )
    commit(
      'set_input_payment_receivables_motors',
      position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE',
    )
    commit('set_input_payment_receivables_bod', position === 'ADMIN')

    commit('set_input_deposit_slip', position === 'ADMIN' || position === 'AR/AP' || position === 'FINANCE')

    commit(
      'set_input_salary', position === 'ADMIN' ||
      position === 'FINANCE' ||
      position === 'HUMAN RESOURCE' ||
      position === 'AUDIT'
      ,
    )
    commit('set_input_salary_deductions', position === 'ADMIN' ||
      position === 'FINANCE' ||
      position === 'AUDIT' ||
      position === 'HUMAN RESOURCE')
    commit(
      'set_input_salary_data',
      position === 'ADMIN' ||
      position === 'FINANCE' ||
      position === 'HUMAN RESOURCE'
      ,
    )
    commit(
      'set_input_salary_monthpa',
      position === 'ADMIN' || position === 'HUMAN RESOURCE' || position === 'FINANCE',
    )

    commit(
      'set_input_monthly_liquidation',
      position === 'ADMIN' ||
      position === 'ACCOUNTING' ||
      position === 'FINANCE' ||
      position === 'CMD' ||
      position === 'AMD' ||
      position === 'PROPERTY CUSTODIAN' ||
      position === 'HUMAN RESOURCE' ||
      position === 'EXECUTIVE' ||
      position === 'BOOKKEEPING' ||
      position === 'IT' ||
      position === 'CHAPEL AUDIT' ||
      position === 'EMPLOYEE' ||
      position === 'LEGAL' ||
      position === 'AUDIT'
      ,
    )
    commit(
      'set_input_monthly_liquidation_reg',
      position === 'ADMIN' || position === 'ACCOUNTING' || position === 'FINANCE' || position === 'CMD' ||
      position === 'BOOKKEEPING',)
    commit(
      'set_input_monthly_liquidation_ca',
      position === 'ADMIN' || position === 'ACCOUNTING' ||
      position === 'FINANCE' || position === 'CMD',
    )
    commit(
      'set_input_monthly_liquidation_department',
      position === 'ADMIN' ||
      position === 'ACCOUNTING'
      ,
    )

    commit(
      'set_input_vouchers',
      position === 'ADMIN' || position === 'TREASURY' || position === 'AMD',
    )
    commit('set_input_vouchers_cv', position === 'ADMIN' || position === 'TREASURY')
    commit(
      'set_input_vouchers_ckv',
      position === 'ADMIN' || position === 'TREASURY' || position === 'AMD',
    )
    commit('set_input_vouchers_ftv', position === 'ADMIN' || position === 'TREASURY')

    commit('set_input_voucher_maintenance', position === 'ADMIN')
    commit('set_input_maintenance', position === 'ADMIN' || position === 'ACCOUNTING' || position === 'FINANCE')
    commit('set_input_liquidations_maintenance', position === 'ADMIN' || position === 'ACCOUNTING' || position === 'FINANCE')

    commit('set_load_interface', position === 'ADMIN' || position === 'PROPERTY CUSTODIAN')
    commit(
      'set_load_interface_stocks',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )
    commit(
      'set_load_interface_stocks_equipment',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )
    commit(
      'set_load_interface_stocks_car',
      position === 'ADMIN' || position === 'PROPERTY CUSTODIAN',
    )
  },
  logout_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/logout-user-profile', data)
        .then(response => {
          commit('set_authenticated', false)
          commit('set_already_generate_salary_scheme', false)

          commit('set_position', 'NA')
          commit('set_user_id', 0)
          commit('set_id_no', 'CHAP-0-0000')
          commit('set_name', 'NA')

          commit('set_month_of', 'NA')
          commit('set_year', 'NA')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  force_logout_credentials({commit, rootGetters}, data) {
    axios.defaults.baseURL = rootGetters['system_data/url']
    return new Promise((resolve, reject) => {
      axios
        .post('api/force-logout-credentials', data)
        .then(response => {
          commit('set_authenticated', false)
          commit('set_already_generate_salary_scheme', false)

          commit('set_position', 'NA')
          commit('set_user_id', 0)
          commit('set_id_no', 'CHAP-0-0000')
          commit('set_name', 'NA')

          commit('set_month_of', 'NA')
          commit('set_year', 'NA')
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  set_already_generate_salary_schemeee({commit, rootGetters}, data) {
    commit('set_already_generate_salary_scheme', true)
  },
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
