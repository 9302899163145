var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pt-4",attrs:{"flat":""}},[_c('v-layout',{staticClass:"mx-2",attrs:{"row":"","wrap":""}},[_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":"","md12":""}},[_vm._v(" LEGENDS - (REMARKS- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"}},[_vm._v(" "+_vm._s(_vm.icons.mdiSwapHorizontal)+" ")]),_vm._v(" ),(DELETE- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]),_vm._v(" ),(EDIT- "),_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilCircle)+" ")]),_vm._v(" ) "),_c('h2',[_vm._v("Total: P "+_vm._s(_vm.total_amount)+" |Print "),(_vm.data_items.length>0 && !_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"success"},on:{"click":function($event){return _vm.print_data()}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPrinter)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1)],1),_c('v-flex',{staticClass:"mx-2",attrs:{"xs12":"","md12":""}},[_c('v-data-table',{attrs:{"dense":"","headers":_vm.headers,"items":_vm.data_items,"height":"600"},scopedSlots:_vm._u([{key:"item.equipment_unit_quantity",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.equipment_unit_quantity>0?item.equipment_unit_quantity:'')+" ")])])]}},{key:"item.equipment_unit_price",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.equipment_unit_price>0?_vm.formatPrice(item.equipment_unit_price):'')+" ")])])]}},{key:"item.total",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s((item.equipment_unit_price*item.equipment_unit_quantity)>0?_vm.formatPrice(item.equipment_unit_price*item.equipment_unit_quantity):'')+" ")])])]}},{key:"item.remarks",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_vm._v(" "+_vm._s(item.specify_remarks!=''?item.remarks+' ('+item.specify_remarks+')':'')+" ")])])]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.id>0)?_c('tr',[_c('td',{staticClass:"text-center"},[(!_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilCircle)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1),_c('td',{staticClass:"text-center"},[(!_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"info"},on:{"click":function($event){return _vm.transfer(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiSwapHorizontal)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1)]):_vm._e()]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [(item.id>0)?_c('tr',[(item.remarks!='DAMAGE')?_c('td',{staticClass:"text-center"},[(!_vm.is_deleting)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":function($event){return _vm.delete_data(item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")]):_vm._e(),(_vm.is_deleting)?_c('v-progress-circular',{attrs:{"color":"info","indeterminate":""}}):_vm._e()],1):_vm._e()]):_vm._e()]}}])})],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"30%"},model:{value:(_vm.is_change_bank_details),callback:function ($$v) {_vm.is_change_bank_details=$$v},expression:"is_change_bank_details"}},[_c('TransferData',{key:_vm.key,attrs:{"details":_vm.details},on:{"response":_vm.on_change}}),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.is_change_bank_details = false}}},[_vm._v(" Close")])],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"30%"},model:{value:(_vm.is_edit),callback:function ($$v) {_vm.is_edit=$$v},expression:"is_edit"}},[_c('EditData',{key:_vm.key,attrs:{"details":_vm.details,"type":'Equipment'},on:{"response":_vm.on_change}}),_c('v-btn',{attrs:{"color":"error"},on:{"click":function($event){_vm.is_edit = false}}},[_vm._v(" Close")])],1),_c('snack-bar-dialog',{attrs:{"snackbar_flag":this.snackbar,"color":this.snackbar_color,"snackbar_text":this.snackbar_text}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }